import Draggable from "react-draggable";
import {onDraggableStart} from "../../../../../common/helpers/utils";
import React, {useRef, useState} from "react";
import './Footer.css';

function TermsModal({show, setShow}) {

    function dismissModal(e) {
        e.preventDefault();
        setShow(false);
    }

    const [bounds, setBounds] = useState({left: 0, right: 0});
    const draggleRef = useRef(null);

    return (
        <div className={show === false ? "modal fade" : "modal fade show customModal"} role="dialog"
             style={show === false ? {display: 'none'} : {display: 'block'}} id="confirmationModal"
             aria-labelledby="confirmationModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

            <Draggable handle=".handler"
                       bounds={bounds}
                       onStart={(event, uiData) => onDraggableStart(event, uiData, draggleRef, bounds, setBounds)}>

                <div className="modal-dialog modal-lg modal-dialog-centered" role="document" ref={draggleRef}>
                    <div className="modal-content group_main_form">
                        <div className={"modal-header handler"}>
                            <h4 className="modal-title" id="customerModalLabel">Terms of Use</h4>
                            <i className="fa fa-arrows ml-3 mt-1  modal_drag_context_icon"/>
                            <button type="button" className="close" onClick={dismissModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mr-auto modal_body_height">

                            <div id="comp-llpfqyle" className="KcpHeO tz5f0K comp-llpfqyle wixui-rich-text"
                                 data-testid="richTextElement"><p className="font_8 wixui-rich-text__text"
                            ><span
                                className="wixui-rich-text__text info_model_bold"><span
                                className="wixui-rich-text__text info_model_text"><span
                                className="wixui-rich-text__text info_model_span">TitleLogix Solutions, LLC Terms of Use</span></span></span>
                            </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">LAST UPDATED AUGUST 23, 2023</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">These Terms of Use (the “Terms”) apply to websites, online platforms, and mobile applications (collectively, the “Sites”) in the control of TitleLogix Solutions, LLC, its successors and assigns, and affiliated companies (“we, “us”, “our”, and “TitleLogix”.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">As a condition of accessing or using the Sites, you acknowledge that you have read and understand these Terms and that the Terms are a legal contract between you and TitleLogix governing your use of the Sites. &nbsp;You also agree to be bound by any rules and policies published on the Sites. &nbsp;By accessing or using the Sites, you also certify that you are at least 18 years of age, reside in the United States, and that, if you are acting on behalf of another person, you are authorized to do so. &nbsp;
                                        <br/>The information and materials contained in the Sites are for marketing and information purposes only and are not intended to be a complete disclosure about TitleLogix and its business, products, or services. &nbsp;For further information regarding TitleLogix please contact us directly at&nbsp;</span></span><span
                                    className="wixui-rich-text__text info_model_span_dc"><a
                                    href="mailto:inquire@titlelogixsolutions.com" target="_self"
                                    className="wixui-rich-text__text"><span
                                    className="wixui-rich-text__text info_model_bold">inquire@titlelogixsolutions.com</span></a></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">READ THE TERMS CAREFULLY BEFORE ACCESSING OR SING ANY OF THE SITES. &nbsp;USE OF THE SITES CONTISTUTES YOUR ACCEPTANCE OF THESE TERMS. &nbsp;IF YOU DO NOT AGREE TO THESE TERMS, YOU ARE NOT PERMITTED TO ACCESS OR USE THE SITES.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Use of the Sites</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">We grant you a non-transferable, non-exclusive, limited license to access and use the Sites solely in connection with viewing the Sites for your personal or internal business purposes. &nbsp;Unless you have explicit written permission, you must not reproduce, modify, rent, lease, sell, trade, distribute, transmit, broadcast, publicly perform, create derivative works based on, or exploit for any commercial purposes, any portion, use of, or access to, the Sites (including User-Created Content, advertisements, API’, and software). &nbsp;You may access and use the Sites only for lawful purposes and in accordance with these Terms.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">You acknowledge that no joint venture, partnership, employment, or agency relationship exists between you and TitleLogix as a result of these Terms or use of the Sites. &nbsp;You agree not to hold yourself out as a representative, agent, or employee of TitleLogix, and that TitleLogix shall not be liable for any representative, act, or omission by you.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">The Sites are intended only for use by U.S. residents. &nbsp;We make no claim that the Sites or their contents are accessible or appropriate outside the United States. &nbsp;The Sites may not be used by any person or entity in an jurisdiction or country where any such distribution or use would be contrary to local law or regulations. &nbsp;Not all products and services are available in all areas, and your eligibility for any particular product or&nbsp;</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">service is subject to the final approval and acceptance of TItleLogix. &nbsp;TitleLogix may discontinue or make changes to the information, products, or services described in the Sites at any time without prior notice.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">You are solely responsible for providing the equipment needed to access the Sits. &nbsp;You are responsible for ensuring that all persons who access the Sites through your internet connection are aware of these Terms and comply with them.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">By accessing or using the Sites, you agree that you will comply with all applicable laws and will not:</span></span>
                                </p>

                                <ul className="font_8 wixui-rich-text__text"
                                >
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Copy any portion of the Sites or use any tool, device, or process (manual or automatic) to gather, extract, monitor, or copy any information contained within the Sites;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Access or attempt to access any portions of the Sites which are password-protected, secure, or nonpublic areas of the Site that you are not authorized to access;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Send, expose, disclose, reveal, or share non private information through the Sites without the express written consent of the owner of the information;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Remove, conceal any proprietary notices from the Sites;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Use the Sites for the benefit of any third party or in any manner not permitted by the licenses granted herein;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Interfere with the operation of the Sites, the server(s) on which the Sites are stored, or any associated server, computer, or database, or others’ enjoyment or use of the same;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Place any software, code, or communication, such as cookies, spyware, robots or viruses, or other malicious or technologically harmful material, on the Sites;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Access, create, or modify source, object, or other code of the sites in any way;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Create, pose, display, publish, or distribute any link to any page of the Sites;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Use the Sites to, or in any way that would, violate any applicable local, state, national, or international law, regulation, ordinance or practice having the force of law;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Use of the Sites to exploit or harm, or attempt to exploit or harm, TitleLogix or others;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Transmit or procure the sending of any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or similar solicitation;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Advertise or offer to sell any goods for services for any commercial purpose;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Conduce ot forward surveys, contests, pyramid schemes, or chain letters;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Impersonate or attempt to impersonate TItleLogix, its employees, or anyone else;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text info_model_span info_model_text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Engage in any other conduct that we, in our sole discretion, determine may harm TitleLogix or users of the Sites, or expose TitleLogix or them to liability; and</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text info_model_span">Use any robot, spider, or other automatic device, process or means to access the Sites for any purpose, including monitoring or copying any material from the Sites.</span>
                                        </p>
                                    </li>
                                </ul>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">If you violate any of the terms of this Section, your permission to use the Sites automatically terminates.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Accounts</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">You may be required to obtain a use account to access certain Sites. &nbsp;When you create an account with us, you must provide information that is accurate, complete, and current. &nbsp;Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Sites.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">You are responsible for safeguarding your username, password, and other log-in information (e.g., answers to security questions) (“Credentials”) that you use to access the Sites and for any activities or actions under your Credentials. &nbsp;You must immediately log off from your account at the end of each session.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span info_model_text">You agree not to disclose your Credentials to any third party.&nbsp; You are solely responsible for all uses of your Credentials, including but not limited to, any account activity or transactions conducted through the use of your Credentials, whether or not authorized by you.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">I you have an existing account with us, your account may be governed by an account or license agreement. &nbsp;If there is any conflict between these Terms and your account or license agreement, the account or license agreement shall govern.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">If you become aware of any known or suspected unauthorized use(s) of your account, or any known or suspected breach of security, including loss, theft, or unauthorized disclosure of your Credentials, you must immediately notify us by emailing us at . &nbsp;You must ensure that you exit from your account at the end of each session. &nbsp;You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other confidential information.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Intellectual Property</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">The Sites and their content, features, and functionality are and will remain the exclusive property of TitleLogix and its licensors. &nbsp;The Sites are protected by copyright, trademark,&nbsp;</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">and other laws of both the United States and foreign countries. &nbsp;Our name and logo, and all related names, logos, product and service names, designs, and slogans are trademarks of TitleLogix and its licensors. &nbsp;Our trademarks and trade dress may not be used for any purpose without the prior written consent of TItleLogix. &nbsp;You have no right, title, or interest in or to the Sites or any of their contents. &nbsp;All rights not expressly granted are expressly reserved by TitleLogix.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">We will respond to notices of alleged copyright infringement that comply with applicable law. &nbsp;If you believe any materials accessible on or from the Sites infringe your copyright, you may request removal of those materials (or access to them) from the Sites by submitting written notification to our copyright agent designated below. &nbsp;In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millenium Copyright Act (17 U.S.C. § 512) (“DMCA Notice”) must include the following:</span></span>
                                </p>

                                <ul className="font_8 wixui-rich-text__text info_model_span info_model_text">
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Adequate information by which we can contact you (including your name, address, telephone number, and email address, if available);</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Identification of the allegedly infringing material that is to be removed or disabled, and information reasonably precise to permit us to locate the material (including, if possible, a link to the material);</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works are covered by a single claim, a representative list of such works;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">A statement tat you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; and</span></span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text"><span
                                            className="wixui-rich-text__text info_model_text"><span
                                            className="wixui-rich-text__text info_model_span">A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</span></span>
                                        </p>
                                    </li>
                                </ul>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Please include “DMCA Notice” in the RE line.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Our Copyright Agent can be reached at: Law Offices of James P. Moon, PLLC, 101 Vintage Dr., Suite 100, Red Oak, Texas 75154, or by phone at 800-214-0639.</span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective. &nbsp;Please be aware that if you knowingly materially&nbsp;</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">misrepresent that material or activity on the Sites is infringing your copyright, you may be held liable for damages (including costs and attorneys’ fees) under Section 512(f) of the DMCA.</span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Express Written Consent to Receive Communications from TitleLogix</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">By agreeing to these Terms, you expressly consent to and authorize TitleLogix, agents, and its third-party representatives to use written, electronic, or verbal means to contact you for any reason, including regarding information about services and products, reminders, confirmations, and marketing of any kind, including offers, solicitations, and promotions. &nbsp;This express written consent includes but is not limited to contact by manual calling methods, prerecorded or artificial voice messages, text messages, emails, and/or automatic telephone dialing systems. &nbsp;You agree that TItleLogix and its agents, and its third-party representatives may, now or in the future, use any email address or telephone number you provide to TitleLogix to contact you for any reason, regardless of whether you incur charges as a result. &nbsp;You expressly agree that such consent may not be revoked at any time for any reason.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Site Issues</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">If you detect or discover any issues or failures regarding the operation, function, availability or security of the Sites, please notify us by emailing a description of the issue to.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Termination</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">We may terminate or suspend your account immediately, without prior notice of liability, for any reason at our sole discretion. &nbsp;Upon termination, your right to use the Sites will immediately cease. &nbsp;All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, limitations of liability, and arbitration provisions.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Indemnification</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">To the fullest extent allowed by applicable law, your agree to indemnify and hold harmless TitleLogix and its licensee and licensors, and their employees, contractors, agents, officers and directors, affiliates, service providers, successors, and assigns, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees), resulting from or arising our of your use and access of Sites, use and access to the Sites by any person using your Credentials, your alleged violation or breach of these Terms.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Limitation of Liability</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">In no event shall TitleLogix, its directors, employees, partners, agents, suppliers, or</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">affiliates, be liable to you or to any other person for any indirect, incidental, special, consequential, exemplary, punitive damages, including without limitation, loss oof profits, data, use, goodwill, business interruption, or other intangible losses, arising out of or in connection with the Sites, including, without limitation, use, inability to use, or unauthorized use of the Sites, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed or should &nbsp;have known of the possibility of such damage. &nbsp;In no event shall TItleLogix be liable for any losses or damages incurred as a result of a third party’s use of your Credentials, whether or not such use is with our knowledge or consent.&nbsp;</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Disclaimer</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Your use of the Sites is at your sole risk. &nbsp;The Sites are provided “AS IS” and AS AVAILABLE,” without warranties of any kind, where express, implied, or statutory. &nbsp;TitleLogix expressly disclaims all implied or stator warranties of merchantability, fitness for a particular use or purpose, quality, non-infringement, or course of performance.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">TItleLogix does not warrant that the Sites’ function will be uninterrupted, secure, or available or that the Sites are free of errors, defects, viruses, or other harmful components. &nbsp;You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to the Sites for any reconstruction of any lost data. &nbsp;TitleLogix does not warrant that the Sites will be compatible with all browsers, operating systems, hardware and software, or that the Sites will meet your requirements. &nbsp;We do not warrant the accuracy, completeness, or usefulness of information on the Sites. &nbsp;All statements and/or opinions, other than content provided by TitleLogix, are solely the opinions and responsibility of the person or entity providing those materials. &nbsp;These materials do not necessarily reflect the onion of TitleLogix and we accept no responsibility for ensuring their accuracy.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Exclusions and Limitations</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span info_model_text">To the extent permitted by law, you and TitleLogix each agree to limit claims for damages or other monetary relief against each other to direct and actual damages or stator damages, regardless of the theory of liability. &nbsp;This means that neither you nor TitleLogix will seek any indirect, special, consequential, treble, or punitive damages from the other. &nbsp;This limitation and waiver also applies to any claims you may bring against any other party to the extent that TitleLogix would be required to indemnify that party for such claim. &nbsp;You agree TitleLogix is not liable for problems caused by you or a third party, or by any act of nature. &nbsp;Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for damages, so some of the above exclusions or limitations of liability may not apply to you.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">To the extent that TitleLogix may not, as a matter of applicable, law, disclaim any implied&nbsp;</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">warranty or limits its liabilities, the scope and duration of such warranty and the extent of TitleLogix’s liability shall be the minimum permitted under such applicable law, and its aggregate liability under such circumstances for liabilities that otherwise would have been limited shall not exceed one hundred dollars ($100).</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Governing Law</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">These Terms shall be governed and construed in accordance with the laws of the state of Texas. &nbsp;If you reside outside the United States, then these Terms shall be governed and construed by the laws of the State of Texas without regard to its conflict of laws principles. &nbsp;Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. &nbsp;If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. &nbsp;These Terms of Use and our Privacy Policy constitute the entire agreement between us regarding our Sites, and supersede and replace any prior agreements, both written and oral, we might have between us regarding the Sites.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Changes to the Sites</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">TitleLogix reserves the right to modify, suspend, or discontinue the Sites at any time and without prior notice to you. &nbsp;Be aware that TitleLogix may make changes to the Sites and the Sites’ functionality and content at any time without notice. &nbsp;TitleLogix makes no commitment whatsoever to keep the Sites functioning or updated.</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_bold"><span
                                        className="wixui-rich-text__text info_model_text"><span
                                        className="wixui-rich-text__text info_model_span">Changes to Terms of Use</span></span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text"><span
                                    className="wixui-rich-text__text info_model_text"><span
                                    className="wixui-rich-text__text info_model_span">We may update the Terms at any time and without prior notice to you. &nbsp;All changes are effective immediately upon posting, but apply only prospectively. &nbsp;By continuing to access or use our Sites after any revisions to the Terms become effective, you agree to be bound by the revised Terms. &nbsp;If you do not agree to the new Terms, you must stop using the Sies. &nbsp;You should check this page frequently so you are aware of any changes.

                                    <br/>
                                    <br/>
                                    <span className="wixui-rich-text__text info_model_bold">Contact Us</span>
                                    <br/>
                                    <br/>
                                    <p className="font_8 wixui-rich-text__text">
                                        <span
                                            className="wixui-rich-text__text info_model_span">For questions or concerns about TITLELOGIX’s California Privacy Notice and privacy practices, or to exercise any of your California Privacy Rights, please email <span
                                            className="wixui-rich-text__text info_model_span_dc"><a
                                            href="mailto:inquire@titlelogixsolutions.com" target="_self"
                                            className="wixui-rich-text__text">inquire@titlelogixsolutions.com</a>,<br/></span>or call 713-453-8822, or contact us by mail at the below address.&nbsp;</span>
                                    </p>


                                    <div className={"center_span mt-3"}>
                                        TitleLogix Solutions, LLC
                                        <br className="wixui-rich-text__text"/>
                                        13750 San Pedro Ave.
                                        <br className="wixui-rich-text__text"/>
                                        Suite 730
                                        <br className="wixui-rich-text__text"/>
                                        San Antonio, TX 78232
                                    </div>
                                    </span>
                                </span>
                                </p>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className={"cursor btn cm_btn"}
                                    onClick={dismissModal}>Close
                            </button>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    )
}

export default TermsModal;