import React, {useEffect, useMemo} from 'react';
import './App.css';
import './Form.css';
import '../features/authentication/templates/Login.css';
import loadable from 'react-loadable'
import {Redirect, Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {alertActions} from "../features/alert/alert";
import 'react-toastify/dist/ReactToastify.css';
import {homeActions} from "../features/home/home";
import {Routes} from "./routes";
import {history} from "../common/helpers/history";
import Preloader from "../common/components/Preloader";
import Navbar from "../features/layout/Navbar";
import Footer from "../features/layout/template/modal/Default/Footer";
import Header from "../features/layout/Header";
import {tenantActions} from "../features/admin/tenant/tenant";

const LoginPage = loadable({
    loader: () => import('../features/authentication/templates/LoginPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const VerifyMfaForm = loadable({
    loader: () => import('../features/authentication/templates/VerifyMfaForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddUserForm = loadable({
    loader: () => import('../features/user/users/templates/AddUserForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const Users = loadable({
    loader: () => import('../features/user/users/templates/Users'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const Orders = loadable({
    loader: () => import('../features/order/templates/Orders'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const Customers = loadable({
    loader: () => import('../features/customer/templates/Customers'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const Home = loadable({
    loader: () => import('../features/home/templates/Home'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddCustomerForm = loadable({
    loader: () => import('../features/customer/templates/AddCustomerForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const UpdateUserForm = loadable({
    loader: () => import('../features/user/users/templates/UpdateUserForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const CustomerDetailPage = loadable({
    loader: () => import('../features/customer/templates/CustomerDetailPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const BranchDetailPage = loadable({
    loader: () => import('../features/customer/templates/branch/UpdateBranchForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const Counties = loadable({
    loader: () => import('../features/data/county/templates/Counties'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddCountyForm = loadable({
    loader: () => import('../features/data/county/templates/AddCountyForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const HighRiskAccountVerified = loadable({
    loader: () => import('../features/data/highRiskAccountVerified/templates/HighRiskAccountVerified'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const HighRiskAccount = loadable({
    loader: () => import('../features/data/highRiskAccount/templates/HighRiskAccount'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const States = loadable({
    loader: () => import('../features/data/state/templates/States'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddStateForm = loadable({
    loader: () => import('../features/data/state/templates/AddStateForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateCountyForm = loadable({
    loader: () => import('../features/data/county/templates/UpdateCountyForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateStateForm = loadable({
    loader: () => import('../features/data/state/templates/UpdateStateForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const StateCodes = loadable({
    loader: () => import('../features/data/stateCode/templates/StateCodes'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const Tenants = loadable({
    loader: () => import('../features/admin/tenant/templates/Tenant'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateTenantForm = loadable({
    loader: () => import('../features/admin/tenant/templates/UpdateTenantForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddStateCodeForm = loadable({
    loader: () => import('../features/data/stateCode/templates/AddStateCodeForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateStateCodeForm = loadable({
    loader: () => import('../features/data/stateCode/templates/UpdateStateCodeForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddOrderForm = loadable({
    loader: () => import('../features/order/templates/AddOrderForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const OrderDetailPage = loadable({
    loader: () => import('../features/order/templates/OrderDetailPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const TaxCertForm = loadable({
    loader: () => import('../features/order/templates/Editor/TaxCert/TaxCertForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});
const NationalTaxCertForm = loadable({
    loader: () => import('../features/order/templates/NationalEditor/TaxCert/TaxCertForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const HoaCertEditorForm = loadable({
    loader: () => import('../features/order/templates/Editor/Hoa/HoaCertForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const ForgotPassword = loadable({
    loader: () => import('../features/authentication/forgotPassword/templates/ForgotPassword'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const ForgotPasswordCheck = loadable({
    loader: () => import('../features/authentication/forgotPassword/templates/ForgotPasswordCheck'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const CreatePasswordForm = loadable({
    loader: () => import('../features/authentication/forgotPassword/templates/CreatePasswordForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const Collectors = loadable({
    loader: () => import('../features/data/collector/templates/Collectors'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddCollectorForm = loadable({
    loader: () => import('../features/data/collector/templates/AddCollectorForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateJurisdictionForm = loadable({
    loader: () => import('../features/data/jurisdiction/templates/JurisdictionDetailPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddJurisdictionForm = loadable({
    loader: () => import('../features/data/jurisdiction/templates/AddJurisdictionForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const Jurisdictions = loadable({
    loader: () => import('../features/data/jurisdiction/templates/Jurisdictions'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const ReOrderForm = loadable({
    loader: () => import('../features/order/templates/ReOrderForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const Roles = loadable({
    loader: () => import('../features/user/role/templates/Roles'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddRoleForm = loadable({
    loader: () => import('../features/user/role/templates/AddRoleForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateRoleForm = loadable({
    loader: () => import('../features/user/role/templates/UpdateRoleForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const CollectorDetailsForm = loadable({
    loader: () => import('../features/data/collector/templates/CollectorDetailsForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const TaxRoll = loadable({
    loader: () => import('../features/data/taxRoll/templates/TaxRoll'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const UpdateTaxRoll = loadable({
    loader: () => import('../features/data/taxRoll/templates/UpdateTaxRoll'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const Subdivision = loadable({
    loader: () => import('../features/data/subDivision/templates/Subdivision'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddSubDivisionForm = loadable({
    loader: () => import('../features/data/subDivision/templates/AddSubDivisionForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const SubdivisionDetail = loadable({
    loader: () => import('../features/data/subDivision/templates/SubdivisionDetail'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const SystemProperties = loadable({
    loader: () => import('../features/admin/systemProperty/templates/SystemProperty'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateSystemPropertyForm = loadable({
    loader: () => import('../features/admin/systemProperty/templates/UpdateSystemPropertyForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddSystemPropertyForm = loadable({
    loader: () => import('../features/admin/systemProperty/templates/AddSystemPropertyForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const Accounting = loadable({
    loader: () => import('../features/accounting/template/Accounting'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AppraisalRoll = loadable({
    loader: () => import('../features/data/appraisalRoll/templates/AppraisalRoll'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AppraisalRollDetailPage = loadable({
    loader: () => import('../features/data/appraisalRoll/templates/AppraisalRollDetailPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const MetroArea = loadable({
    loader: () => import('../features/data/msa/templates/MetroArea'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddMetroAreaForm = loadable({
    loader: () => import('../features/data/msa/templates/AddMetroAreaForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const MetroAreaUpdatedForm = loadable({
    loader: () => import('../features/data/msa/templates/MetroAreaUpdateForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const Profiles = loadable({
    loader: () => import('../features/profile/templates/Profile'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const ManagementCompany = loadable({
    loader: () => import('../features/Hoa/managementCompany/templates/ManagementCompany'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AutoHoa = loadable({
    loader: () => import('../features/Hoa/auto-hoa/templates/AutoHoa'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddAutoHoaForm = loadable({
    loader: () => import('../features/Hoa/auto-hoa/templates/AddAutoHoaForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const UpdateAutoHoaForm = loadable({
    loader: () => import('../features/Hoa/auto-hoa/templates/UpdateAutoHoaForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddManagementCompanyForm = loadable({
    loader: () => import('../features/Hoa/managementCompany/templates/AddManagementCompanyForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const ManagementCompanyDetailPage = loadable({
    loader: () => import('../features/Hoa/managementCompany/templates/ManagementCompanyDetailPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const Associations = loadable({
    loader: () => import('../features/Hoa/association/templates/Associations'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const BillReleases = loadable({
    loader: () => import('../features/data/billRelease/templates/BillRelease'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const CollectorLinks = loadable({
    loader: () => import('../features/data/collectorLink/templates/CollectorLinks'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddCollectorLink = loadable({
    loader: () => import('../features/data/collectorLink/templates/AddCollectorLinkForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const UpdateCollectorLink = loadable({
    loader: () => import('../features/data/collectorLink/templates/UpdateCollectorLinkForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const ExternalBillReleases = loadable({
    loader: () => import('../features/external/billRelease/templates/BillRelease'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddAssociationForm = loadable({
    loader: () => import('../features/Hoa/association/templates/AddAssociationForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AssociationDetailsForm = loadable({
    loader: () => import('../features/Hoa/association/templates/AssociationDetail'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const Teams = loadable({
    loader: () => import('../features/user/team/templates/Teams'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddTeamForm = loadable({
    loader: () => import('../features/user/team/templates/AddTeamForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const TeamDetailPage = loadable({
    loader: () => import('../features/user/team/templates/TeamDetailPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const NotFoundPage = loadable({
    loader: () => import('../common/components/NotFoundPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const UnauthorizedErrorPage = loadable({
    loader: () => import('../common/components/UnauthorizedErrorPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});
const AccessDeniedErrorPage = loadable({
    loader: () => import('../common/components/AccessDeniedErrorPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const ImportVersion = loadable({
    loader: () => import('../features/admin/importVersion/templates/ImportVersion'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const Code = loadable({
    loader: () => import('../features/admin/code/templates/Code'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddCodeForm = loadable({
    loader: () => import('../features/admin/code/templates/AddCodeForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const UpdateCodeForm = loadable({
    loader: () => import('../features/admin/code/templates/UpdateCodeForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const NoteRule = loadable({
    loader: () => import('../features/admin/noteLogic/templates/NoteLogic'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddNoteLogic = loadable({
    loader: () => import('../features/admin/noteLogic/templates/AddNoteLogic'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateNoteLogic = loadable({
    loader: () => import('../features/admin/noteLogic/templates/UpdateNoteLogic'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const QcLogic = loadable({
    loader: () => import('../features/admin/qcLogic/templates/QcLogic'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AddQcLogicForm = loadable({
    loader: () => import('../features/admin/qcLogic/templates/AddQcLogicForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const UpdateQcLogicForm = loadable({
    loader: () => import('../features/admin/qcLogic/templates/updateQcLogicForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const RegisteredScrapers = loadable({
    loader: () => import('../features/admin/workerScraper/templates/RegisteredScrapers'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const ScraperModule = loadable({
    loader: () => import('../features/admin/scraperModule/templates/ScraperModule'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const Payment = loadable({
    loader: () => import('../features/accounting/template/payment/Payment'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddScraperModule = loadable({
    loader: () => import('../features/admin/scraperModule/templates/AddScraperModule'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateScraperModule = loadable({
    loader: () => import('../features/admin/scraperModule/templates/UpdateScraperModule'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const Translation = loadable({
    loader: () => import('../features/admin/accountTrans/templates/Translation'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddAccountTranslationForm = loadable({
    loader: () => import('../features/admin/accountTrans/templates/AddAccountTranslationForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const UpdateAccountTranslationForm = loadable({
    loader: () => import('../features/admin/accountTrans/templates/UpdateAccountTranslationForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const RecordAudit = loadable({
    loader: () => import('../features/admin/recordAudit/templates/RecordAudit'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const RecordAuditDetail = loadable({
    loader: () => import('../features/admin/recordAudit/templates/RecordAuditDetailPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const TurnAroundReport = loadable({
    loader: () => import('../features/reports/reports/templates/TurnAroundReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const AccountsReceivableReport = loadable({
    loader: () => import('../features/reports/reports/templates/AccountsReceivableReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const RevenueReport = loadable({
    loader: () => import('../features/reports/reports/templates/RevenueReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});
const FulfillmentCandidateReport = loadable({
    loader: () => import('../features/reports/reports/templates/FulfillmentCandidateReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const FulfillmentCustomerReport = loadable({
    loader: () => import('../features/reports/reports/templates/FulfillmentCustomerReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const ProductAssignmentReport = loadable({
    loader: () => import('../features/reports/reports/templates/ProductAssignmentReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const CountyReport = loadable({
    loader: () => import('../features/reports/reports/templates/CountyReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AutovsManualReport = loadable({
    loader: () => import('../features/reports/reports/templates/AutovsManualReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AggregateUserEfficiencyReport = loadable({
    loader: () => import('../features/reports/reports/templates/AggregateUserEfficiencyReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const DailyOrderCountReport = loadable({
    loader: () => import('../features/reports/reports/templates/DailyOrderCountReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const DetailedUserEfficiencyReport = loadable({
    loader: () => import('../features/reports/reports/templates/DetailedUserEfficiencyReport'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const FNFTransaction = loadable({
    loader: () => import('../features/accounting/template/fnf/Transactions'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const RevenueEvent = loadable({
    loader: () => import('../features/accounting/template/revenueEvent/RevenueEvent'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const ReportHistory = loadable({
    loader: () => import('../features/reports/report-history/templates/ReportHistory'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const OrderEvent = loadable({
    loader: () => import('../features/reports/orderEvents/templates/OrderEvent'),
    loading: () => <Preloader/>,
    timeout: 5000
});
const HoaFeetype = loadable({
    loader: () => import('../features/Hoa/sortHoaFeeType/templates/SortHoaFeeType'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const CannedNotes = loadable({
    loader: () => import('../features/data/cannedNote/templates/CannedNotes'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const ErrorLogs = loadable({
    loader: () => import('../features/admin/error/templates/ErrorLogs'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const FollowUpTypes = loadable({
    loader: () => import('../features/admin/followUpType/template/FollowUpType'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddFollowUpTypes = loadable({
    loader: () => import('../features/admin/followUpType/template/AddFollowUpType'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateFollowUpTypes = loadable({
    loader: () => import('../features/admin/followUpType/template/UpdateFollowUpType'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const LinkedAccount = loadable({
    loader: () => import('../features/data/linkedAccount/templates/LinkedAccount'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddLinkedAccountForm = loadable({
    loader: () => import('../features/data/linkedAccount/templates/AddLinkedAccountForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateLinkedAccountForm = loadable({
    loader: () => import('../features/data/linkedAccount/templates/UpdateLinkedAccountForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddBillReleaseForm = loadable({
    loader: () => import('../features/data/billRelease/templates/AddBillReleaseForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddCannedNotesForm = loadable({
    loader: () => import('../features/data/cannedNote/templates/AddCannedNotesForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const CannedNotesDetailsPage = loadable({
    loader: () => import('../features/data/cannedNote/templates/CannedNotesDetailsPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateBillReleaseForm = loadable({
    loader: () => import('../features/data/billRelease/templates/UpdateBillReleaseForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const ThirdPartyProviders = loadable({
    loader: () => import('../features/Hoa/thirdPartyProvider/templates/ThirdPartyProvider'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddThirdPartyProviderForm = loadable({
    loader: () => import('../features/Hoa/thirdPartyProvider/templates/AddThirdPartyProviderForm'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const ThirdPartyProviderDetailPage = loadable({
    loader: () => import('../features/Hoa/thirdPartyProvider/templates/ThirdPartyProviderDetailPage'),
    loading: () => <Preloader/>,
    timeout: 5000
});


const TaxRoundingConfigs = loadable({
    loader: () => import('../features/data/taxRoundingConfig/templates/TaxRoundingConfig'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const AddTaxRoundingConfigs = loadable({
    loader: () => import('../features/data/taxRoundingConfig/templates/AddTaxRoundingConfig'),
    loading: () => <Preloader/>,
    timeout: 5000
});

const UpdateTaxRoundingConfigs = loadable({
    loader: () => import('../features/data/taxRoundingConfig/templates/UpdateTaxRoundingConfig'),
    loading: () => <Preloader/>,
    timeout: 5000
});
export const jwtDecode = () => {
    try {
        const token = localStorage.getItem("authToken")
        // @ts-ignore
        const arrayToken = token.split('.');
        return JSON.parse(atob(arrayToken[1]));
    } catch (error) {
        // If Parsing token results in error means token is malformed and user must return to login screen
        window.location.href = '/login';
    }
}
export const isTokenExpired = () => {
    return jwtDecode()?.exp < (Date.now() / 1000);
}
function App() {
    const dispatch = useDispatch();
    const tenantData = useSelector(state => state.getTenantByUrlReducer);

    useEffect(() => {
        if (localStorage.getItem('user')) {
            dispatch(homeActions.fetchEnumsAction());
            dispatch(homeActions.fetchAuthoritiesAction());
        }
        history.listen(() => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }, []); // Empty dependency array, runs only once

    useEffect(() => {
        const baseUrl = `${window.location.host}`;
        if (baseUrl) {
            dispatch(tenantActions.getTenantByUrlAction(baseUrl));
        }
    }, []);

    const isTokenValid = () => {
        const isUserLoggedIn = localStorage.getItem("authToken");
        return isUserLoggedIn && !isTokenExpired();
    }

    const RouteWithLoader = ({ component: Component, ...rest }) => {
        if (!isTokenValid()) {
           localStorage.removeItem("authToken");
           localStorage.removeItem("user");
        }
        if(!tenantData.items){
            return <Preloader/>;
        }
        let FooterComponent = Footer;
        // switch (tenantData.items.name) {
        //
        //     default:
        //         FooterComponent = Footer;
        //         break;
        // }
        return (
            <Route
                {...rest}
                render={props => (
                    <>
                        <Component {...props} />
                        <FooterComponent />
                    </>
                )}
            />
        );
    };


    const RouteWithSidebar = ({component: Component, ...rest}) => {
        // useBeforeUnload();
        return (
            <Route {...rest} render={props => {

                if (!isTokenValid() || !localStorage.getItem('user')) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
                }
                // logged in so return component
                return <>
                    <Header/>
                    <div className="d-flex">
                        <Navbar/>
                        <div className="main_content w-100">
                            <Component {...props} />
                        </div>

                    </div>
                </>
            }}/>
        );
    };

    /******************* Custom routes Start Here *************/

    return (
        <Switch>
            {/* unAuth pages */}
            <RouteWithLoader exact path={Routes.Login.path} component={LoginPage} />
            <RouteWithLoader exact path={Routes.VerifyMfaForm.path} component={VerifyMfaForm} />

            {/***forgot password routes***/}
            <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
            <RouteWithLoader exact path={Routes.ForgotPasswordCheck.path} component={ForgotPasswordCheck} />
            <RouteWithLoader exact path={Routes.CreatePasswordForm.path} component={CreatePasswordForm} />
            <RouteWithLoader exact path={Routes.RestPasswordForm.path} component={CreatePasswordForm} />
            <RouteWithLoader exact path={Routes.ExternalBillRelease.path} component={ExternalBillReleases} />

            <RouteWithSidebar exact path={Routes.Home.path} component={Home}/>
            <RouteWithSidebar exact path={Routes.Default.path} component={Home}/>

            <RouteWithSidebar exact path={Routes.Orders.path} component={Orders}/>
            <RouteWithSidebar exact path={Routes.OrderForm.path} component={AddOrderForm}/>
            <RouteWithSidebar exact path={Routes.OrderUpdateForm.path} component={ReOrderForm}/>
            <RouteWithSidebar exact path={Routes.OrderDetailPage.path} component={OrderDetailPage}/>
            <RouteWithSidebar exact path={Routes.taxCertEditorPage.path} component={TaxCertForm}/>
            <RouteWithSidebar exact path={Routes.taxCertNationalEditorPage.path} component={NationalTaxCertForm}/>

            <RouteWithSidebar exact path={Routes.hoaFullEditor.path} component={HoaCertEditorForm}/>
            <RouteWithSidebar exact path={Routes.hoaContactEditor.path} component={HoaCertEditorForm}/>

            <RouteWithSidebar exact path={Routes.Users.path} component={Users}/>
            <RouteWithSidebar exact path={Routes.UserForm.path} component={AddUserForm}/>
            <RouteWithSidebar exact path={Routes.UpdateUserForm.path} component={UpdateUserForm}/>

            <RouteWithSidebar exact path={Routes.Roles.path} component={Roles}/>
            <RouteWithSidebar exact path={Routes.RoleForm.path} component={AddRoleForm}/>
            <RouteWithSidebar exact path={Routes.UpdateRoleForm.path} component={UpdateRoleForm}/>

            <RouteWithSidebar exact path={Routes.Customers.path} component={Customers}/>
            <RouteWithSidebar exact path={Routes.CustomerAddForm.path} component={AddCustomerForm}/>
            <RouteWithSidebar exact path={Routes.CustomerDetailPage.path} component={CustomerDetailPage}/>
            <RouteWithSidebar exact path={Routes.BranchDetailPage.path} component={BranchDetailPage}/>

            <RouteWithSidebar exact path={Routes.Accounting.path} component={Accounting}/>
            <RouteWithSidebar exact path={Routes.CustomerPricing.path} component={Accounting}/>
            <RouteWithSidebar exact path={Routes.PartnerPricing.path} component={Accounting}/>
            <RouteWithSidebar exact path={Routes.VendorPricing.path} component={Accounting}/>
            <RouteWithSidebar exact path={Routes.FulfillmentPricing.path} component={Accounting}/>

            <RouteWithSidebar exact path={Routes.Collectors.path} component={Collectors}/>
            <RouteWithSidebar exact path={Routes.CollectorForm.path} component={AddCollectorForm}/>
            <RouteWithSidebar exact path={Routes.CollectorDetailsForm.path} component={CollectorDetailsForm}/>


            <RouteWithSidebar exact path={Routes.Counties.path} component={Counties}/>
            <RouteWithSidebar exact path={Routes.CountyForm.path} component={AddCountyForm}/>
            <RouteWithSidebar exact path={Routes.UpdateCountyForm.path} component={UpdateCountyForm}/>

            <RouteWithSidebar exact path={Routes.HighRiskAccountVerified.path} component={HighRiskAccountVerified}/>

            <RouteWithSidebar exact path={Routes.HighRiskAccount.path} component={HighRiskAccount}/>

            <RouteWithSidebar exact path={Routes.Jurisdictions.path} component={Jurisdictions}/>
            <RouteWithSidebar exact path={Routes.JurisdictionForm.path} component={AddJurisdictionForm}/>
            <RouteWithSidebar exact path={Routes.UpdateJurisdictionForm.path} component={UpdateJurisdictionForm}/>


            <RouteWithSidebar exact path={Routes.States.path} component={States}/>
            <RouteWithSidebar exact path={Routes.StateForm.path} component={AddStateForm}/>
            <RouteWithSidebar exact path={Routes.UpdateStateForm.path} component={UpdateStateForm}/>


            <RouteWithSidebar exact path={Routes.StateCodes.path} component={StateCodes}/>
            <RouteWithSidebar exact path={Routes.StateCodeForm.path} component={AddStateCodeForm}/>
            <RouteWithSidebar exact path={Routes.UpdateStateCodeForm.path} component={UpdateStateCodeForm}/>

            <RouteWithSidebar exact path={Routes.TaxRoll.path} component={TaxRoll}/>
            <RouteWithSidebar exact path={Routes.updateTaxRoll.path} component={UpdateTaxRoll}/>

            <RouteWithSidebar exact path={Routes.AppraisalRoll.path} component={AppraisalRoll}/>
            <RouteWithSidebar exact path={Routes.AppraisalRollDetailPage.path} component={AppraisalRollDetailPage}/>

            <RouteWithSidebar exact path={Routes.SubDivision.path} component={Subdivision}/>
            <RouteWithSidebar exact path={Routes.SubDivisionForm.path} component={AddSubDivisionForm}/>
            <RouteWithSidebar exact path={Routes.SubDivisionDetailsForm.path} component={SubdivisionDetail}/>

            <RouteWithSidebar exact path={Routes.SystemProperty.path} component={SystemProperties}/>

            <RouteWithSidebar exact path={Routes.AddSystemPropertyForm.path} component={AddSystemPropertyForm}/>
            <RouteWithSidebar exact path={Routes.UpdateSystemPropertyForm.path} component={UpdateSystemPropertyForm}/>
            <RouteWithSidebar exact path={Routes.Profile.path} component={Profiles}/>

            <RouteWithSidebar exact path={Routes.MetroArea.path} component={MetroArea}/>
            <RouteWithSidebar exact path={Routes.MetroAreaForm.path} component={AddMetroAreaForm}/>
            <RouteWithSidebar exact path={Routes.MetroAreaUpdateForm.path} component={MetroAreaUpdatedForm}/>

            <RouteWithSidebar exact path={Routes.ManagementCompany.path} component={ManagementCompany}/>
            <RouteWithSidebar exact path={Routes.ManagementCompanyForm.path} component={AddManagementCompanyForm}/>
            <RouteWithSidebar exact path={Routes.UpdateManagementCompany.path} component={ManagementCompanyDetailPage}/>

            <RouteWithSidebar exact path={Routes.AutoHoa.path} component={AutoHoa}/>
            <RouteWithSidebar exact path={Routes.AutoHoaForm.path} component={AddAutoHoaForm}/>
            <RouteWithSidebar exact path={Routes.UpdateAutoHoa.path} component={UpdateAutoHoaForm}/>

            <RouteWithSidebar exact path={Routes.Associations.path} component={Associations}/>
            <RouteWithSidebar exact path={Routes.AssociationForm.path} component={AddAssociationForm}/>
            <RouteWithSidebar exact path={Routes.AssociationDetailsForm.path} component={AssociationDetailsForm}/>

            <RouteWithSidebar exact path={Routes.Teams.path} component={Teams}/>
            <RouteWithSidebar exact path={Routes.TeamForm.path} component={AddTeamForm}/>

            <RouteWithSidebar exact path={Routes.TeamDetailPage.path} component={TeamDetailPage}/>

            <RouteWithSidebar exact path={Routes.ImportVersion.path} component={ImportVersion}/>

            <RouteWithSidebar exact path={Routes.UnAuthorized.path} component={UnauthorizedErrorPage}/>
            <RouteWithSidebar exact path={Routes.AccessDenied.path} component={AccessDeniedErrorPage}/>


            <RouteWithSidebar exact path={Routes.Codes.path} component={Code}/>
            <RouteWithSidebar exact path={Routes.CodeForm.path} component={AddCodeForm}/>
            <RouteWithSidebar exact path={Routes.UpdateCodeFrom.path} component={UpdateCodeForm}/>

            <RouteWithSidebar exact path={Routes.NoteRules.path} component={NoteRule}/>
            <RouteWithSidebar exact path={Routes.AddNoteRule.path} component={AddNoteLogic}/>
            <RouteWithSidebar exact path={Routes.UpdateNoteRule.path} component={UpdateNoteLogic}/>
            <RouteWithSidebar exact path={Routes.QcRules.path} component={QcLogic}/>
            <RouteWithSidebar exact path={Routes.QcRuleForm.path} component={AddQcLogicForm}/>
            <RouteWithSidebar exact path={Routes.UpdateQcRule.path} component={UpdateQcLogicForm}/>


            <RouteWithSidebar exact path={Routes.Payments.path} component={Payment}/>

            <RouteWithSidebar exact path={Routes.OrderEvents.path} component={OrderEvent}/>
            <RouteWithSidebar exact path={Routes.HoaFeetype.path} component={HoaFeetype}/>


            <RouteWithSidebar exact path={Routes.AccountTranslation.path} component={Translation}/>
            <RouteWithSidebar exact path={Routes.AddAccountTranslation.path} component={AddAccountTranslationForm}/>
            <RouteWithSidebar exact path={Routes.UpdateAccountTranslation.path}
                              component={UpdateAccountTranslationForm}/>

            <RouteWithSidebar exact path={Routes.RecordAudit.path} component={RecordAudit}/>
            <RouteWithSidebar exact path={Routes.RecordAuditDetail.path}
                              component={RecordAuditDetail}/>


            <RouteWithSidebar exact path={Routes.RegisteredScrapers.path} component={RegisteredScrapers}/>

            <RouteWithSidebar exact path={Routes.ScraperModule.path} component={ScraperModule}/>
            <RouteWithSidebar exact path={Routes.AddScraperModule.path} component={AddScraperModule}/>
            <RouteWithSidebar exact path={Routes.UpdateScraperModule.path} component={UpdateScraperModule}/>

            <RouteWithSidebar exact path={Routes.FNFTransactionPage.path} component={FNFTransaction}/>
            <RouteWithSidebar exact path={Routes.RevenueEvent.path} component={RevenueEvent}/>

            <RouteWithSidebar exact path={Routes.TurnAroundReport.path} component={TurnAroundReport}/>
            <RouteWithSidebar exact path={Routes.AccountsReceivableReport.path} component={AccountsReceivableReport}/>
            <RouteWithSidebar exact path={Routes.RevenueReport.path} component={RevenueReport}/>
            <RouteWithSidebar exact path={Routes.CountyReport.path} component={CountyReport}/>
            <RouteWithSidebar exact path={Routes.AutovsManualReport.path} component={AutovsManualReport}/>
            <RouteWithSidebar exact path={Routes.AggregateUserEfficiencyReport.path}
                              component={AggregateUserEfficiencyReport}/>
            <RouteWithSidebar exact path={Routes.DailyOrderCountReport.path} component={DailyOrderCountReport}/>
            <RouteWithSidebar exact path={Routes.DetailedUserEfficiencyReport.path}
                              component={DetailedUserEfficiencyReport}/>
            <RouteWithSidebar exact path={Routes.FulfillmentCandidateReport.path}
                              component={FulfillmentCandidateReport}/>
            <RouteWithSidebar exact path={Routes.FulfillmentCustomerReport.path} component={FulfillmentCustomerReport}/>
            <RouteWithSidebar exact path={Routes.ProductAssignmentReport.path} component={ProductAssignmentReport}/>


            <RouteWithSidebar exact path={Routes.ReportHistory.path} component={ReportHistory}/>
            <RouteWithSidebar exact path={Routes.ErrorLogs.path} component={ErrorLogs}/>

            <RouteWithSidebar exact path={Routes.CannedNotes.path} component={CannedNotes}/>
            <RouteWithSidebar exact path={Routes.AddCannedNotes.path} component={AddCannedNotesForm}/>
            <RouteWithSidebar exact path={Routes.UpdateCannedNotes.path} component={CannedNotesDetailsPage}/>

            <RouteWithSidebar exact path={Routes.FollowUpTypes.path} component={FollowUpTypes}/>
            <RouteWithSidebar exact path={Routes.CreateFollowUpTypes.path} component={AddFollowUpTypes}/>
            <RouteWithSidebar exact path={Routes.UpdateFollowUpTypes.path} component={UpdateFollowUpTypes}/>

            <RouteWithSidebar exact path={Routes.LinkedAccount.path} component={LinkedAccount}/>
            <RouteWithSidebar exact path={Routes.AddLinkedAccountForm.path} component={AddLinkedAccountForm}/>
            <RouteWithSidebar exact path={Routes.UpdateLinkedAccountForm.path} component={UpdateLinkedAccountForm}/>


            <RouteWithSidebar exact path={Routes.BillRelease.path} component={BillReleases}/>
            <RouteWithSidebar exact path={Routes.AddBillReleaseForm.path} component={AddBillReleaseForm}/>
            <RouteWithSidebar exact path={Routes.UpdateBillReleaseForm.path} component={UpdateBillReleaseForm}/>

            <RouteWithSidebar exact path={Routes.Tenants.path} component={Tenants}/>
            <RouteWithSidebar exact path={Routes.UpdateTenantForm.path} component={UpdateTenantForm}/>

            <RouteWithSidebar exact path={Routes.CollectorLinks.path} component={CollectorLinks}/>
            <RouteWithSidebar exact path={Routes.AddCollectorLinkForm.path} component={AddCollectorLink}/>
            <RouteWithSidebar exact path={Routes.UpdateCollectorLinkForm.path} component={UpdateCollectorLink}/>

            <RouteWithSidebar exact path={Routes.ThirdPartyProviders.path} component={ThirdPartyProviders}/>
            <RouteWithSidebar exact path={Routes.AddThirdPartyProviderForm.path} component={AddThirdPartyProviderForm}/>
            <RouteWithSidebar exact path={Routes.ThirdPartyProviderDetailPage.path}
                              component={ThirdPartyProviderDetailPage}/>

            <RouteWithSidebar exact path={Routes.TaxRoundingConfigurations.path} component={TaxRoundingConfigs}/>
            <RouteWithSidebar exact path={Routes.AddTaxRoundingConfigurationForm.path}
                              component={AddTaxRoundingConfigs}/>
            <RouteWithSidebar exact path={Routes.UpdateTaxRoundingConfigurationForm.path}
                              component={UpdateTaxRoundingConfigs}/>

            <RouteWithSidebar path={"*"} component={NotFoundPage}/>

        </Switch>

    );
}

export const initialState = {
    loading: false,
    loggedIn: false,
    items: [],
    error: null, authorities: []

};

export default App;
