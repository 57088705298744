import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from "../root.reducer";

// Create a logger middleware for logging actions
const loggerMiddleware = createLogger();

// Middleware array
const middleware = [
    thunkMiddleware,
    // loggerMiddleware
];

// Create the Redux store with rootReducer and middleware
export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);
