import Label from "../../../../common/helpers/Label";
import {
    CommonSelectDropDownTag,
    CommonSubmitLoader,
    isUserHaveAccess,
    useAuthorities
} from "../../../../common/helpers/utils";
import {history} from "../../../../common/helpers/history";
import {Form} from "../../../../common/components/Form";
import React, {useEffect, useState} from "react";
import {systemActions} from "../systemProperty";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router";
import {customerActions} from "../../../customer/customer";

const SystemPropertyField = ({formType, systemPropertyData, isReadOnly = false}) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const authorities = useAuthorities();
    let {systemPropertyId} = useParams();

    const [systemPropertyName, setSystemPropertyName] = useState(null);
    const [systemPropertyValue, setSystemPropertyValue] = useState(null);
    const [disable, setDisable] = useState(false);
    const [tenant, setTenant] = useState(null);
    const storageData = useSelector(state => state.utilDataReducer);
    const tenantData = useSelector(state => state.getAllTenantReducer);

    useEffect(()=>{
        dispatch(customerActions.getAllTenantAction());
    },[]);

    useEffect(() => {
        if (systemPropertyData != null && systemPropertyData.items) {
            setSystemPropertyName(systemPropertyData.items.name);
            setSystemPropertyValue(systemPropertyData?.items?.value === '*******' ? '' : systemPropertyData.items.value);
        }
    }, [systemPropertyData]);

    const input = {name: systemPropertyName, value: systemPropertyValue, tenant: tenant};

    function handleSubmit(e) {
        e.preventDefault();
        const {from} = location.state || {from: {pathname: "/system-properties"}};
        if (input) {
            setDisable(true);
            // get return url from location state to cities page
            if (formType === "Update")
                dispatch(systemActions.updateSystemPropertyAction(input, systemPropertyId, from, setDisable));
            else
                dispatch(systemActions.createSystemPropertyAction(input, from, setDisable));
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit} fields={<>
                <div className="form_header">
                    <h2>{formType} System Property</h2>
                    <hr className="mb-4"/>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4 col-form-label">
                        <Label text={"Name"} required={true}/>
                    </div>
                    <div className="col-sm-8">
                        <input className="form-control" type="text" name="Name"
                               value={systemPropertyName}
                               onChange={(e) => setSystemPropertyName(e.target.value)}
                               placeholder="Enter Name..." autoComplete={false}
                               required={true}
                               readOnly={isReadOnly}/>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-4 col-form-label">
                        <Label text={"Value"} required={true}/>
                    </div>

                    <div className="col-sm-8">
                        <input className="form-control" type="text" name="Value"
                               value={systemPropertyValue}
                               onChange={(e) => setSystemPropertyValue(e.target.value)}
                               placeholder="Enter Value..." autoComplete={false}
                               required={true}
                               readOnly={isReadOnly}/>
                    </div>
                </div>

                {storageData?.items && storageData?.items?.customerLevel === 'SYS' &&
                    <div className="form-group row">

                        <div className={"col-sm-4 col-form-label"}>
                            <Label text={`Tenant`} required={false}/>
                        </div>

                        <div className="col-sm-8">

                            {CommonSelectDropDownTag(tenantData?.items, "name", "name", "Select Tenant...", null, false, tenant, setTenant, false, isReadOnly === true, null, (value) => {
                                setTenant(value);
                            }, true, false)}

                        </div>
                    </div>
                }

                {isUserHaveAccess(authorities, ["SPRO-U"]) === true &&
                    <div className="form-group row">
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-8">
                            <button className="cm_btn cm_bg"
                                    onClick={() => history.push("/system-properties")}>Cancel
                            </button>
                            {CommonSubmitLoader(disable, formType)}
                        </div>
                    </div>}
            </>
            }/>
        </>
    )
}
export default SystemPropertyField;