import Draggable from "react-draggable";
import {onDraggableStart} from "../../../../../common/helpers/utils";
import React, {useRef, useState} from "react";
import './Footer.css';

function PrivacyModal({show, setShow}) {

    function dismissModal(e) {
        e.preventDefault();
        setShow(false);
    }

    const [bounds, setBounds] = useState({left: 0, right: 0});
    const draggleRef = useRef(null);

    return (
        <div className={show === false ? "modal fade" : "modal fade show customModal"} role="dialog"
             style={show === false ? {display: 'none'} : {display: 'block'}} id="confirmationModal"
             aria-labelledby="confirmationModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

            <Draggable handle=".handler"
                       bounds={bounds}
                       onStart={(event, uiData) => onDraggableStart(event, uiData, draggleRef, bounds, setBounds)}>

                <div className="modal-dialog modal-lg modal-dialog-centered" role="document" ref={draggleRef}>
                    <div className="modal-content group_main_form">
                        <div className={"modal-header handler"}>
                            <h4 className="modal-title" id="customerModalLabel">Privacy</h4>
                            <i className="fa fa-arrows ml-3 mt-1  modal_drag_context_icon"/>
                            <button type="button" className="close" onClick={dismissModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mr-auto modal_body_height">

                            <div id="comp-llpkuyrm" className="KcpHeO tz5f0K comp-llpkuyrm wixui-rich-text"
                                 data-testid="richTextElement">
                                <p className="font_8 wixui-rich-text__text"
                                ><span
                                    className="wixui-rich-text__text info_model_span"><span
                                    className="wixui-rich-text__text info_model_bold">TitleLogix Solutions, LLC Privacy Notice</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">Effective January 1, 2023</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">TitleLogix Solutions, LLC respects and is committed to protecting your privacy. &nbsp;This Privacy Notice explains if personal information is collected and used.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span"><span
                                        className="wixui-rich-text__text info_model_bold">Collection of Personal Information</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">TitleLogix does not collect any Non-public Information.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">TitleLogix may collect the following category of Information that is required to register for permitted use of certain TitleLogix Sites or applications.</span>
                                </p>

                                <ul className="font_8 wixui-rich-text__text info_model_span font_8">
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text font_8"><span
                                            className="wixui-rich-text__text info_model_span">Contact information (e.g., name, address, phone number and email address).</span>
                                        </p>
                                    </li>
                                </ul>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span"><span
                                        className="wixui-rich-text__text info_model_bold">Collection of Browsing Information</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">TitleLogix automatically collects the following types of Browsing Information when you access a TitleLogix website, online service, or application (each a “TitleLogix Website”) from your Internet browser, computer, and/or device:</span>
                                </p>


                                <ul className="font_8 wixui-rich-text__text"
                                >
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text font_8">Internet
                                            Protocol (IP) address and operating system</p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text font_8"><span
                                            className="wixui-rich-text__text info_model_span">Browser version, language, and type;</span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text font_8"><span
                                            className="wixui-rich-text__text info_model_span">Domain name system requests; and</span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text font_8"><span
                                            className="wixui-rich-text__text info_model_span">Browsing history on the TitleLogix Website, such as date and time of your visit to the TitleLogix Website and visits to the pages within the TitleLogix Website.</span>
                                        </p>
                                    </li>
                                </ul>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">Like most websites, our servers automatically log each visitor to the TitleLogix Website and may collect the Browsing Information described above. &nbsp;We use Browsing Information for system administration, troubleshooting, fraud investigation, and to improve our websites. &nbsp;Browsing Information generally does not reveal anything personal about you, though if you have created a user account for a TitleLogix Website and are logged into that account, the TitleLogix Website may be able to link certain browsing activity to your user account.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span"><span
                                        className="wixui-rich-text__text info_model_bold">Other Online Specifics</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">When you visit a TitleLogix Website, a “cookie” may be sent to your computer. &nbsp;A cookie is a small piece of data that is sent to your Internet browser from a web server and stored on your computer’s hard drive. &nbsp;Information gathered using cookies helps us improve our user experience. &nbsp;For example, a cookie can help the website load properly or can customize the display page based on your browser type and user preferences. &nbsp;You can choose whether or not to accept cookies by changing your Internet browser settings. &nbsp;Be aware that doing so may impair or limit some functionality of the TitleLogix&nbsp;
                                        Website.</span></p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">
                                        <span className="wixui-rich-text__text info_model_span_dc">Web Beacons</span>
                                        <br className="wixui-rich-text__text info_model_span"/>
                                            We use web beacons to determine when and how many times a page has been viewed. &nbsp;This information is used to improve our websites.
                                        <br className="wixui-rich-text__text"/>
                                            <span
                                                className="wixui-rich-text__text info_model_span_dc">Do Not Track</span>
                                        <br className="wixui-rich-text__text info_model_span"/>
                                            Currently our TitleLogix Websites do not respond to “Do Not Track” features enables through your browser.
                                    </span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span"><span
                                        className="wixui-rich-text__text info_model_bold">Use of Personal Information</span>&nbsp;</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">TitleLogix does not collect or use Personal Information. &nbsp;The only information that TitleLogix collects from you is that which is required for you create a User Name and Password required to access TitleLogix proprietary Websites such as TGX.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span"><span
                                        className="wixui-rich-text__text info_model_bold">When Information is Disclosed</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">TitleLogix will not disclose any information related to you or your browsing history, with the exception of the following:</span>
                                </p>


                                <ul className="font_8 wixui-rich-text__text"
                                >
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text font_8">To enable us
                                            to detect or prevent criminal activity, fraud, material misrepresentation,
                                            or nondisclosure;</p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text font_8"><span
                                            className="wixui-rich-text__text info_model_span">To law enforcement or authorities in connection with an investigation, or in response to a subpoena or court order; or&nbsp;</span>
                                        </p>
                                    </li>
                                    <li className="wixui-rich-text__text">
                                        <p className="font_8 wixui-rich-text__text font_8"><span
                                            className="wixui-rich-text__text info_model_span">In the good-faith belief that such disclosure is necessary to comply with legal process or applicable laws, or to protest the rights, property, or safety of TitleLogix, its customers, or the public.</span>
                                        </p>
                                    </li>
                                </ul>


                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">The law does not require your prior authorization and does not allow you to restrict the disclosures described above. &nbsp;Additionally, we may disclose your information to third parties for whom you have given us authorization or consent to make such disclosure. &nbsp;We do not otherwise share your Information or Browsing Information with nonaffiliated third parties, except as required or permitted by law.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">We reserve the right to transfer your Information, Browsing Information, and other information, in connection with the sale or other disposition of all or part of the TitleLogix business and/or assets, or in the event of bankruptcy, reorganization, insolvency, receivership, or an assignment for the benefit of creditors. &nbsp;By submitting Information and/or Browsing Information to TitleLogix, you expressly agree and consent to the use and/or transfer of the foregoing information in connection with any of the above described proceedings.&nbsp;</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span"><span
                                        className="wixui-rich-text__text info_model_bold">Your Consent to This Privacy Notice; Notice Changes</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span">By submitting Information or Browsing Information to TitleLogix, you consent to the<br/>

                                        collection and use of the information in accordance with this Privacy Notice. &nbsp;We may change this Privacy Notice at any time. &nbsp;The Privacy Notice’s effective date will show the last date changes were made. &nbsp;If you provide information to us following any change of the Privacy Notice, that signifies your assent to and acceptance of the changes to the Privacy Notice.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">

                                    <span
                                        className="wixui-rich-text__text info_model_span"><span
                                        className="wixui-rich-text__text info_model_bold">Accessing and Correcting Information; Contact Us</span></span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">


                                    <span className="wixui-rich-text__text">If you have question contact TitleLogix by phone at 713-453-8822 or by email at <span
                                        className="wixui-rich-text__text info_model_span_dc"><a
                                        href="mailto:inquire@titlelogixsolutions.com" target="_self"
                                        className="wixui-rich-text__text">inquire@titlelogixsolutions.com</a></span>, or by mail to:</span>


                                    <div className={"center_span mt-3"}>
                                        TitleLogix Solutions, LLC
                                        <br className="wixui-rich-text__text"/>
                                        13750 San Pedro Ave.
                                        <br className="wixui-rich-text__text"/>
                                        Suite 730
                                        <br className="wixui-rich-text__text"/>
                                        San Antonio, TX 78232
                                        <br className="wixui-rich-text__text"/>
                                        Attn: Privacy Officer
                                    </div>
                                </p>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className={"cursor btn cm_btn"}
                                    onClick={dismissModal}>Close
                            </button>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    )
}

export default PrivacyModal;