// src/common/helpers/axiosInstance.js

import axios from 'axios';

// Create an Axios instance with a default base URL
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:8023',
});

// Add a request interceptor to set the Authorization header dynamically (without 'Bearer')
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken'); // Get the token dynamically
        if (token) {
            config.headers['Authorization'] = token; // Set token directly without 'Bearer'
        }

        const baseUrl = window?.location?.host;
        if (baseUrl) {
            config.headers['X-Tenant-Url'] = baseUrl;
        }

        return config;
    },
    (error) => Promise.reject(error) // Handle errors if the request fails
);

export default axiosInstance;

