import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {alertActions} from "../../alert/alert";
import {serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";


//export actions
export const teamRuleActions = {
    getAllData,
    createTeamRuleAction,
    getTeamRuleByIdAction,
    updateTeamRuleAction,
    deleteTeamRuleAction,
    getPreviewOrderListAction
}


//export constants
export const teamRuleConstants = {
    GET_ALL_TEAM_RULE_REQUEST: 'GET_ALL_TEAM_RULE_REQUEST',
    GET_ALL_TEAM_RULE_SUCCESS: 'GET_ALL_TEAM_RULE_SUCCESS',
    GET_ALL_TEAM_RULE_FAILURE: 'GET_ALL_TEAM_RULE_FAILURE',


    CREATE_TEAM_RULE_REQUEST: 'CREATE_TEAM_RULE_REQUEST',
    CREATE_TEAM_RULE_SUCCESS: 'CREATE_TEAM_RULE_SUCCESS',
    CREATE_TEAM_RULE_FAILURE: 'CREATE_TEAM_RULE_FAILURE',

    GET_TEAM_RULE_BY_ID_REQUEST: 'GET_TEAM_RULE_BY_ID_REQUEST',
    GET_TEAM_RULE_BY_ID_SUCCESS: 'GET_TEAM_RULE_BY_ID_SUCCESS',
    GET_TEAM_RULE_BY_ID_FAILURE: 'GET_TEAM_RULE_BY_ID_FAILURE',

    UPDATE_TEAM_RULE_REQUEST: 'UPDATE_TEAM_RULE_REQUEST',
    UPDATE_TEAM_RULE_SUCCESS: 'UPDATE_TEAM_RULE_SUCCESS',
    UPDATE_TEAM_RULE_FAILURE: 'UPDATE_TEAM_RULE_FAILURE',

    DELETE_TEAM_RULE_REQUEST: 'DELETE_TEAM_RULE_REQUEST',
    DELETE_TEAM_RULE_SUCCESS: 'DELETE_TEAM_RULE_SUCCESS',
    DELETE_TEAM_RULE_FAILURE: 'DELETE_TEAM_RULE_FAILURE',

    GET_PREVIEW_ORDERS_REQUEST: 'GET_PREVIEW_ORDERS_REQUEST',
    GET_PREVIEW_ORDERS_SUCCESS: 'GET_PREVIEW_ORDERS_SUCCESS',
    GET_PREVIEW_ORDERS_FAILURE: 'GET_PREVIEW_ORDERS_FAILURE',

}


//export service
export const teamRuleService = {
    getAllTeamRules,
    createTeamRule,
    getTeamRuleById,
    updateTeamRule,
    deleteTeamRule,
    getPreviewOrderList


};

{/********** Actions started here. *****************/
}


//get all team rules Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        teamRuleService.getAllTeamRules(query)
            .then(
                teamRules => dispatch(success(teamRules)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: teamRuleConstants.GET_ALL_TEAM_RULE_REQUEST}
    }

    function success(teamRules) {
        return {type: teamRuleConstants.GET_ALL_TEAM_RULE_SUCCESS, teamRules}
    }

    function failure(error) {
        return {type: teamRuleConstants.GET_ALL_TEAM_RULE_FAILURE, error}
    }
}


//create Team Rule Action
function createTeamRuleAction(obj, setDisable, setShow, teamId) {
    return dispatch => {
        dispatch(request({obj}));

        teamRuleService.createTeamRule(obj)
            .then(
                teamRule => {
                    if (teamRule['errorCode'] === 200) {
                        showSuccessToast("Team Rule added successfully!");
                        dispatch(success(teamRule));
                        dispatch(getAllData({teamId: teamId , sort:"weight" , sortOrder:"DESCENDING" }));
                        setShow(false);
                    } else {
                        responseDTOExceptionHandler(teamRule);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(teamRule) {
        return {type: teamRuleConstants.CREATE_TEAM_RULE_REQUEST, teamRule}
    }

    function success(teamRule) {
        return {type: teamRuleConstants.CREATE_TEAM_RULE_SUCCESS, teamRule}
    }

    function failure(error) {
        return {type: teamRuleConstants.CREATE_TEAM_RULE_FAILURE, error}
    }
}


//get team rule by id
function getTeamRuleByIdAction(teamRuleId) {
    return dispatch => {
        dispatch(request());

        teamRuleService.getTeamRuleById(teamRuleId)
            .then(
                teamRule => dispatch(success(teamRule)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: teamRuleConstants.GET_TEAM_RULE_BY_ID_REQUEST}
    }

    function success(teamRule) {
        return {type: teamRuleConstants.GET_TEAM_RULE_BY_ID_SUCCESS, teamRule}
    }

    function failure(error) {
        return {type: teamRuleConstants.GET_TEAM_RULE_BY_ID_FAILURE, error}
    }
}


// update Team Rule Action
function updateTeamRuleAction(obj, id, setDisable, setShow, teamId) {
    return dispatch => {
        dispatch(request({obj}));

        teamRuleService.updateTeamRule(obj, id)
            .then(
                teamRule => {
                    if (teamRule['errorCode'] === 200) {
                        showSuccessToast("Team Rule updated successfully!");
                        dispatch(success(teamRule));
                        dispatch(getAllData({teamId: teamId , sort:"weight" , sortOrder:"DESCENDING" }));
                        setShow(false);
                    } else {
                        responseDTOExceptionHandler(teamRule);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(teamRule) {
        return {type: teamRuleConstants.UPDATE_TEAM_RULE_REQUEST, teamRule}
    }

    function success(teamRule) {
        return {type: teamRuleConstants.UPDATE_TEAM_RULE_SUCCESS, teamRule}
    }

    function failure(error) {
        return {type: teamRuleConstants.UPDATE_TEAM_RULE_FAILURE, error}
    }

}


// delete Team Rule Action
function deleteTeamRuleAction(teamRuleId, setShowTeamRuleDeleteConfirmation, setDisable, teamId) {
    return dispatch => {
        dispatch(request());
        teamRuleService.deleteTeamRule(teamRuleId)
            .then(
                teamRule => {
                    if (teamRule['errorCode'] === 200) {
                        showSuccessToast("Team Rule deleted successfully!");
                        dispatch(success(teamRule));
                        dispatch(getAllData({teamId: teamId , sort:"weight" , sortOrder:"DESCENDING" }));
                    } else {
                        responseDTOExceptionHandler(teamRule);
                    }
                    setDisable(false);
                    setShowTeamRuleDeleteConfirmation(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: teamRuleConstants.DELETE_TEAM_RULE_REQUEST}
    }

    function success(teamRule) {
        return {type: teamRuleConstants.DELETE_TEAM_RULE_SUCCESS, teamRule}
    }

    function failure(error) {
        return {type: teamRuleConstants.DELETE_TEAM_RULE_FAILURE, error}
    }
}

//get all preview   Action
function getPreviewOrderListAction(type, teamId, teamRuleId, count) {
    return dispatch => {
        dispatch(request());
        teamRuleService.getPreviewOrderList(type, teamId, teamRuleId, count)
            .then(
                orders => {
                    if(orders==="" || orders===null){
                        showSuccessToast("No Order Found!!!")
                    }
                    dispatch(success(orders))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() {
        return {type: teamRuleConstants.GET_PREVIEW_ORDERS_REQUEST}
    }

    function success(orders) {
        return {type: teamRuleConstants.GET_PREVIEW_ORDERS_SUCCESS, orders}
    }

    function failure(error) {
        return {type: teamRuleConstants.GET_PREVIEW_ORDERS_FAILURE, error}
    }

}

/********** Actions ended here. *****************/


/********** Reducers started here. *****************/

export function getAllTeamRuleByTeamIdReducer(state = {}, action) {
    switch (action.type) {
        case teamRuleConstants.GET_ALL_TEAM_RULE_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case teamRuleConstants.GET_ALL_TEAM_RULE_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.teamRules
            };
        case teamRuleConstants.GET_ALL_TEAM_RULE_FAILURE:
            return {};
        default:
            return state
    }
}


//Get Team Rule by id Reducer
export function getTeamRuleByIdReducer(state = {}, action) {
    switch (action.type) {
        case teamRuleConstants.GET_TEAM_RULE_BY_ID_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case teamRuleConstants.GET_TEAM_RULE_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.teamRule
            };
        case teamRuleConstants.GET_TEAM_RULE_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


export function getPreviewOrderListReducer(state = {}, action) {
    switch (action.type) {
        case teamRuleConstants.GET_PREVIEW_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case teamRuleConstants.GET_PREVIEW_ORDERS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.orders
            };
        case teamRuleConstants.GET_PREVIEW_ORDERS_FAILURE:
            return {
                loading: false,
            };
        default:
            return state
    }
}

/********** Reducers ended here. *****************/


/********** Services started here. *****************/

//get teamRule by team id service
export function getAllTeamRules(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/team-rules?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new Team Rule
export function createTeamRule(obj) {
    return axiosInstance.post(`/ajax/create/teamRule`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}

export function getTeamRuleById(teamRuleId) {
    return axiosInstance.get(`/ajax/get-team-rule-by-id/${teamRuleId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function updateTeamRule(obj, teamRuleId) {
    return axiosInstance.put(`/ajax/update/team-rule/${teamRuleId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//delete team Rule by id
export function deleteTeamRule(teamRuleId) {
    return axiosInstance.delete(`/ajax/delete-team-rule-by-id/${teamRuleId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function getPreviewOrderList(type, teamId, teamRuleId, count) {
    return axiosInstance.post(`/ajax/get-rule-preview-result/${type}/${teamId}/${teamRuleId}/${count}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })

}



/********** Services ended here. *****************/


