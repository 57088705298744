import React from 'react';
import {Link} from 'react-router-dom';


function Breadcrumb(props) {
    function isLast(index) {
        return index === props.crumbs.length - 1;
    }

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {props.crumbs.map((crumb, ci) => {
                    const isLastCrumb = isLast(ci);
                    return (
                        <li
                            key={ci}
                            className={`breadcrumb-item ${isLastCrumb ? 'active' : ''}`}
                            aria-current={isLastCrumb ? 'page' : undefined}
                        >
                            {isLastCrumb ? (
                                crumb.content
                            ) : (
                                <a href={crumb.url}>{crumb.content}</a>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>

    )
}

export default Breadcrumb;