import {alertActions} from "../alert/alert";
import {history} from "../../common/helpers/history";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {homeActions} from "../home/home";
import {serialize} from "../../common/helpers/utils";
import axiosInstance from "../../axiosInstance";
import {showErrorToast} from "../../common/components/Toast";


//export actions
export const authenticationActions = {
    loginActions,
    authenticateImpersonatorUserAction,
    issueMfaChallengeAction,
    verifyMfaChallengeAction
};

//export constants
export const authenticationConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    IMPERSONATE_REQUEST: 'IMPERSONATE_REQUEST',
    IMPERSONATE_SUCCESS: 'IMPERSONATE_SUCCESS',
    IMPERSONATE_FAILURE: 'IMPERSONATE_FAILURE',

    ISSUE_MFA_CHALLENGE_REQUEST: 'ISSUE_MFA_CHALLENGE_REQUEST',
    ISSUE_MFA_CHALLENGE_SUCCESS: 'ISSUE_MFA_CHALLENGE_SUCCESS',
    ISSUE_MFA_CHALLENGE_FAILURE: 'ISSUE_MFA_CHALLENGE_FAILURE',

    VERIFY_MFA_CHALLENGE_REQUEST: 'VERIFY_MFA_CHALLENGE_REQUEST',
    VERIFY_MFA_CHALLENGE_SUCCESS: 'VERIFY_MFA_CHALLENGE_SUCCESS',
    VERIFY_MFA_CHALLENGE_FAILURE: 'VERIFY_MFA_CHALLENGE_FAILURE',
};

//export service
export const authenticationService = {
    login,
    authenticateImpersonatorUser,
    issueMfaChallengeService,
    verifyMfaChallengeService
};

{/********************** Actions started here. *****************************/
}

function loginActions(username, password, from, setShowChangePasswordConfirmationModal, location, mfaToken = null, setDisable = null) {
    return dispatch => {
        localStorage.clear();
        dispatch(request(username));
        authenticationService.login(username, password, mfaToken)
            .then(
                result => {

                    if (result['errorCode'] === 200) {
                        if(setDisable){
                            setDisable(false)
                        }
                        if (result.userForcePasswordResetStatus) {
                            setShowChangePasswordConfirmationModal(true);
                        } else {
                            dispatch(success(result));
                            localStorage.setItem('user', "loggedIn");
                            localStorage.setItem("daysToExpirePassword", JSON.stringify(result['daysToExpirePassword']));
                            const token = result?.customAuthTokenDTO?.token;
                            if (token) {
                                localStorage.setItem('authToken', token);  // Store token with a key like 'authToken'
                            } else {
                                console.warn("Token not found in the response.");
                            }
                            if (result['customAuthTokenDTO']['isUserHaveTeam'] !== null) {
                                localStorage.setItem("isUserHaveTeam", JSON.stringify(result['customAuthTokenDTO']['userHaveTeam']));

                            }
                            localStorage.setItem("isUserManager", JSON.stringify(result['customAuthTokenDTO']['isUserManager']));
                            history.push(from);

                            dispatch(success(result));
                            dispatch(homeActions.fetchAuthoritiesAction());

                        }
                        // TODO: Need a better identifier for this
                    } else if (result?.mfaInfo) {
                        let mfaResult = JSON.stringify(result);
                        history.push('/verify-mfa', {
                            ...location.state, // Preserve the existing state
                            mfaResult,          // Add new property
                            username,
                            password
                        });
                        dispatch(failure(result))
                    } else {
                        responseDTOExceptionHandler(result);
                        dispatch(failure(result));
                    }
                },
                error => {
                    console.log("ERROR", error);
                    localStorage.clear();
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request(user) {
        return {type: authenticationConstants.LOGIN_REQUEST, user}
    }

    function success(result) {
        return {type: authenticationConstants.LOGIN_SUCCESS, result}
    }

    function failure(error) {
        return {type: authenticationConstants.LOGIN_FAILURE, error}
    }
}

function authenticateImpersonatorUserAction(username, from) {
    return dispatch => {

        dispatch(request(username));

        authenticationService.authenticateImpersonatorUser(username)
            .then(
                result => {
                    if (result['errorCode'] === 200) {
                        dispatch(homeActions.resetReducerAction("fetchAuthoritiesReducer"));
                        localStorage.setItem('user', "loggedIn");
                        localStorage.setItem("daysToExpirePassword", JSON.stringify(result['daysToExpirePassword']));

                        if (result['customAuthTokenDTO']['isUserHaveTeam'] !== null) {
                            localStorage.setItem("isUserHaveTeam", JSON.stringify(result['customAuthTokenDTO']['userHaveTeam']));

                        }
                        localStorage.setItem("isUserManager", JSON.stringify(result['customAuthTokenDTO']['isUserManager']));
                        history.push(from);
                        dispatch(success(result));
                        window.location.reload();
                    } else {
                        responseDTOExceptionHandler(result);
                    }
                },
                error => {
                    localStorage.clear();
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request(user) {
        return {type: authenticationConstants.IMPERSONATE_REQUEST, user}
    }

    function success(result) {
        return {type: authenticationConstants.IMPERSONATE_SUCCESS, result}
    }

    function failure(error) {
        return {type: authenticationConstants.IMPERSONATE_FAILURE, error}
    }
}

function issueMfaChallengeAction(challengeId, deviceId, remember) {
    return dispatch => {
        dispatch(request());

        authenticationService.issueMfaChallengeService(challengeId, deviceId, remember)
            .then(
                challenge => dispatch(success(challenge)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(user) {
        return {type: authenticationConstants.ISSUE_MFA_CHALLENGE_REQUEST, user}
    }

    function success(result) {
        return {type: authenticationConstants.ISSUE_MFA_CHALLENGE_SUCCESS, result}
    }

    function failure(error) {
        return {type: authenticationConstants.ISSUE_MFA_CHALLENGE_FAILURE, error}
    }
}


function verifyMfaChallengeAction(challengeId, verificationCode, username, password, location, from = null, setDisable, setShowChangePasswordConfirmationModal = () => {}) {
    return dispatch => {
        dispatch(request());
        authenticationService.verifyMfaChallengeService(challengeId, verificationCode)
            .then(
                challenge => {
                    if(challenge && challenge?.token){
                        dispatch(success(challenge));
                        // If challenge is solved, proceed to login with MFA token
                        dispatch(authenticationActions.loginActions(username, password, from, setShowChangePasswordConfirmationModal, location, challenge.token, setDisable));
                    }else{
                        showErrorToast('Invalid verification code.');
                        setDisable(false)
                    }
                }
            )
            .catch(error => {
                // Set error message when challenge fails
                showErrorToast('Invalid verification code.');
                setDisable(false)
                dispatch(failure(error.toString()));
            });
    };

    function request() {
        return {type: authenticationConstants.VERIFY_MFA_CHALLENGE_REQUEST}
    }

    function success(challenge) {
        return {type: authenticationConstants.VERIFY_MFA_CHALLENGE_SUCCESS, challenge}
    }

    function failure(error) {
        return {type: authenticationConstants.VERIFY_MFA_CHALLENGE_FAILURE, error}
    }
}

{/********************** Actions ended here. *****************************/
}

{/********************** Reducers started here. *****************************/
}

//reducer
const initialState = {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authenticationConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
            };
        case authenticationConstants.LOGIN_SUCCESS:
            return {
                loggedIn: false,
                user: action.result
            };
        case authenticationConstants.LOGIN_FAILURE:
            return {
                loggingIn: false,
                mfaInfo: action?.error?.mfaInfo
            };
        case authenticationConstants.LOGOUT:
            return {};
        default:
            return state
    }
}


{/********************************* Reducers ended here. ********************************************/
}


{/******************************* Services started here. ***************************************************/
}

function login(username, password, mfaToken = null) {
    const requestOptions = {
        username: username,
        password: password,
    };

    if (mfaToken) {
        requestOptions.mfaToken = mfaToken;
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.replaceAll('/', '&');

    return axiosInstance.post(`/ajax/authenticate/${timeZone}`, requestOptions).then((response) => {
        return exceptionHandler(response);
    });
}

async function authenticateImpersonatorUser(username) {
    const requestOptions = {username};
    const paramData = serialize(requestOptions);

    try {
        const response = await axiosInstance.post(`/ajax/authenticate/impersonate?${paramData}`);
        const handledResponse = exceptionHandler(response);
        if (handledResponse?.impersonateUserToken) {
            localStorage.setItem("authToken", handledResponse.impersonateUserToken);
        }
        return handledResponse;
    } catch (error) {
        console.error("Authentication failed:", error);
        throw error;
    }
}

export async function issueMfaChallengeService(challengeId, deviceId, remember) {
    let params = {
        challengeId: challengeId, mfaDeviceId: deviceId, remember: remember
    };
    let paramsData = serialize(params);
    return axiosInstance.post(`/authenticate/mfa/challenge?${paramsData}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export async function verifyMfaChallengeService(challengeId, solution) {
    let params = {
        challengeId: challengeId, solution: solution
    };
    let paramsData = serialize(params);
    return axiosInstance.post(`/authenticate/mfa/verify?${paramsData}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}


{/**************************** Services ended here. ***************************/
}



