import Draggable from "react-draggable";
import {onDraggableStart} from "../../../../../common/helpers/utils";
import React, {useRef, useState} from "react";
import './Footer.css';

function CaliforniaPrivacyModal({show, setShow}) {

    function dismissModal(e) {
        e.preventDefault();
        setShow(false);
    }

    const [bounds, setBounds] = useState({left: 0, right: 0});
    const draggleRef = useRef(null);

    return (
        <div className={show === false ? "modal fade" : "modal fade show customModal"} role="dialog"
             style={show === false ? {display: 'none'} : {display: 'block'}} id="confirmationModal"
             aria-labelledby="confirmationModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

            <Draggable handle=".handler"
                       bounds={bounds}
                       onStart={(event, uiData) => onDraggableStart(event, uiData, draggleRef, bounds, setBounds)}>

                <div className="modal-dialog modal-lg modal-dialog-centered" role="document" ref={draggleRef}>
                    <div className="modal-content group_main_form">
                        <div className={"modal-header handler"}>
                            <h4 className="modal-title" id="customerModalLabel">California Privacy</h4>
                            <i className="fa fa-arrows ml-3 mt-1  modal_drag_context_icon"/>
                            <button type="button" className="close" onClick={dismissModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mr-auto modal_body_height">

                            <div id="comp-llpnzz65" className="KcpHeO tz5f0K comp-llpnzz65 wixui-rich-text"
                                 data-testid="richTextElement"><p className="font_8 wixui-rich-text__text"
                            >
                                <span
                                    className="wixui-rich-text__text info_model_span info_model_bold"><span
                                    className="wixui-rich-text__text info_model_span">TITLELOGIX SOLUTIONS, LLC CALIFORNIA PRIVACY NOTICE</span></span>
                            </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">TitleLogix Solutions, LLC and its affiliated companies (collectively, “TITLELOGIX,” “our,” or “we”) respect and are committed to protecting your privacy. This California Privacy Notice explains how we collect, use, and disclose Personal Information, when and to whom we disclose such information, and the rights you, as a California resident (“Consumer”), have regarding your Personal Information (“California Privacy Rights”). “Personal Information” means information that identifies, relates to, describes, and is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household. If TitleLogix has collected, used, or disclosed your Personal Information in relation to a job application or employment, independent contractor, officer, owner, or director relationship with TITLELOGIX, TITLELOGIX’s practices are private and will not be disclose your information to any party except that which is necessary to complete the hiring process of a prospective employee.&nbsp;</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">Some affiliates maintain separate California Privacy Notices or privacy statements. If an affiliate has a separate California Privacy Notice, it will be available on the affiliate’s website, and this California Privacy Notice does not apply.</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">This Personal Information is collected for the following business purpose:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">Information we receive from you on Employment applications or other forms.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">Sale of Personal Information:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">In the preceding 12 months, TITLELOGIX has not sold or shared Personal Information.&nbsp; TITLELOGIX does not sell or share Personal Information.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">Retention Periods:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">TITLELOGIX will only retain any Personal Information obtained in the process of Employment and during the duration of such employment for state and federal record keeping requirements.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">Personal Information of minors:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">TITLELOGIX does NOT knowingly collect the Personal Information of minors. TITLELOGIX does NOT sell or share the information of consumers under 16 years of age.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">Sensitive Personal Information:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">TITLELOGIX does NOT use or disclose sensitive Personal Information for any purposes other than those specified in the California Consumer Privacy Act.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">Right to know:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">Consumers have a right to know about Personal Information collected, used, disclosed, shared, or sold, including the categories of such Personal Information, as well as the purpose for such collection, use, disclosure, sharing, or selling, categories of third parties to whom Personal Information is disclosed, shared or sold, and the specific pieces of Personal Information collected about the consumer. Consumers have the right to request TITLELOGIX to disclose what Personal Information it collected, used, and disclosed in the past 12 months, or since January 1, 2023.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span"><span
                                        className="wixui-rich-text__text info_model_bold">Right to request deletion:</span>&nbsp;</span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">Consumers have a right to request the deletion of their Personal Information, subject to certain exceptions.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">Right to Correct:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">Consumers have the right to correct inaccurate Personal Information.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">Right to non-discrimination:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">Consumers have a right not to be discriminated against because of exercising their consumer privacy rights. We will not discriminate against Consumers for exercising any of their California Privacy Rights.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">Privacy Requests:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">To exercise any of your California Privacy Rights, or if acting an authorized agent on behalf of another individual, please email <span
                                        className="wixui-rich-text__text info_model_span_dc"><a
                                        href="mailto:inquire@titlelogixsolutions.com" target="_self"
                                        className="wixui-rich-text__text">inquire@titlelogixsolutions.com</a></span> or call us at 713-453-8822, or write to the address at the end of this notice.&nbsp;</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">Upon making a California Privacy Request, TITLELOGIX will verify the consumer’s identity by requiring identifying information from the consumer.&nbsp;</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">The above-rights are subject to any applicable rights and obligations including both Federal and California exemptions rendering TITLELOGIX, or Personal Information collected by TITLELOGIX, exempt from certain CCPA requirements.&nbsp;</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">A Consumer may use an Authorized Agent to submit any CCPA request. Authorized agents’ requests will be processed like any other CCPA request, but TITLELOGIX will also require the Consumer provide the agent written permission to make the request and verify his or her identity with TITLELOGIX.&nbsp;</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span">California Privacy Notice – Effective Date:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">This California Privacy Notice was last updated on January 1, 2023.&nbsp;</span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span className="wixui-rich-text__text info_model_span_dc"><span
                                        className="wixui-rich-text__text info_model_span info_model_bold"><span
                                        className="wixui-rich-text__text info_model_span">Contact for more information:&nbsp;</span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text">
                                    <span
                                        className="wixui-rich-text__text info_model_span">For questions or concerns about TITLELOGIX’s California Privacy Notice and privacy practices, or to exercise any of your California Privacy Rights, please email <span
                                        className="wixui-rich-text__text info_model_span_dc"><a
                                        href="mailto:inquire@titlelogixsolutions.com" target="_self"
                                        className="wixui-rich-text__text">inquire@titlelogixsolutions.com</a>,<br/></span>or call 713-453-8822, or contact us by mail at the below address.&nbsp;</span>
                                </p>


                                <div className={"center_span mt-3"}>
                                    TitleLogix Solutions, LLC
                                    <br className="wixui-rich-text__text"/>
                                    13750 San Pedro Ave.
                                    <br className="wixui-rich-text__text"/>
                                    Suite 730
                                    <br className="wixui-rich-text__text"/>
                                    San Antonio, TX 78232
                                    <br className="wixui-rich-text__text"/>
                                    Attn: Privacy Officer
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className={"cursor btn cm_btn"}
                                    onClick={dismissModal}>Close
                            </button>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    )
}

export default CaliforniaPrivacyModal;