import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {resourceToFileForReport, handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import {showInfoToast} from "../../../common/components/Toast";
import axiosInstance from "../../../axiosInstance";

export const reportActions = {
    getAllData,
    exportData,
    getTurnAroundReportAction,
    getRevenueReportAction,
    exportRevenueReportAction,
    getCountyReportAction,
    exportCountyReportAction,
    exportTurnAroundTimeDataAction,
    exportDailyOrderCountAction,
    getUserEfficiencyReportAction,
    exportUserEfficiencyReportAction,
    getAutoVsManualReportAction,
    exportAutoVsManualReportAction,
    getDetailedUserEfficiencyReportAction,
    exportDetailedUserEfficiencyReportAction,
    getAccountsReceivableReportAction,
    getFulfillmentCandidateReportAction,
    exportFulfillmentCandidateReportAction,
    getFulfillmentCustomerReportAction,
    getProductAssignmentReportAction,
    exportProductAssignmentReportAction,
    exportFulfillmentCustomerReportAction,
    exportAccountsReceivableAction,
}

export const reportConstants = {
    GET_ALL_REPORTS_REQUEST: 'GET_ALL_REPORTS_REQUEST',
    GET_ALL_REPORTS_SUCCESS: 'GET_ALL_REPORTS_SUCCESS',
    GET_ALL_REPORTS_FAILURE: 'GET_ALL_REPORTS_FAILURE',

    EXPORT_REPORTS_DATA_REQUEST: 'EXPORT_REPORTS_DATA_REQUEST',
    EXPORT_REPORTS_DATA_SUCCESS: 'EXPORT_REPORTS_DATA_SUCCESS',
    EXPORT_REPORTS_DATA_FAILURE: 'EXPORT_REPORTS_DATA_FAILURE',

    GET_TURN_AROUND_REPORTS_REQUEST: 'GET_TURN_AROUND_REPORTS_REQUEST',
    GET_TURN_AROUND_REPORTS_SUCCESS: 'GET_TURN_AROUND_REPORTS_SUCCESS',
    GET_TURN_AROUND_REPORTS_FAILURE: 'GET_TURN_AROUND_REPORTS_FAILURE',


    GET_REVENUE_REPORTS_REQUEST: 'GET_REVENUE_REPORTS_REQUEST',
    GET_REVENUE_REPORTS_SUCCESS: 'GET_REVENUE_REPORTS_SUCCESS',
    GET_REVENUE_REPORTS_FAILURE: 'GET_REVENUE_REPORTS_FAILURE',

    EXPORT_TURN_AROUND_REPORTS_DATA_REQUEST: 'EXPORT_TURN_AROUND_REPORTS_DATA_REQUEST',
    EXPORT_TURN_AROUND_REPORTS_DATA_SUCCESS: 'EXPORT_TURN_AROUND_REPORTS_DATA_SUCCESS',
    EXPORT_TURN_AROUND_REPORTS_DATA_FAILURE: 'EXPORT_TURN_AROUND_REPORTS_DATA_FAILURE',

    EXPORT_DAILY_ORDER_COUNT_DETAILS_REQUEST: 'EXPORT_DAILY_ORDER_COUNT_DETAILS_REQUEST',
    EXPORT_DAILY_ORDER_COUNT_DETAILS_SUCCESS: 'EXPORT_DAILY_ORDER_COUNT_DETAILS_SUCCESS',
    EXPORT_DAILY_ORDER_COUNT_DETAILS_FAILURE: 'EXPORT_DAILY_ORDER_COUNT_DETAILS_FAILURE',

    EXPORT_REVENUE_REPORTS_DATA_REQUEST: 'EXPORT_REVENUE_REPORTS_DATA_REQUEST',
    EXPORT_REVENUE_REPORTS_DATA_SUCCESS: 'EXPORT_REVENUE_REPORTS_DATA_SUCCESS',
    EXPORT_REVENUE_REPORTS_DATA_FAILURE: 'EXPORT_REVENUE_REPORTS_DATA_FAILURE',

    GET_COUNTY_REPORTS_REQUEST: 'GET_COUNTY_REPORTS_REQUEST',
    GET_COUNTY_REPORTS_SUCCESS: 'GET_COUNTY_REPORTS_SUCCESS',
    GET_COUNTY_REPORTS_FAILURE: 'GET_COUNTY_REPORTS_FAILURE',

    EXPORT_COUNTY_REPORTS_REQUEST: 'EXPORT_COUNTY_REPORTS_REQUEST',
    EXPORT_COUNTY_REPORTS_SUCCESS: 'EXPORT_COUNTY_REPORTS_SUCCESS',
    EXPORT_COUNTY_REPORTS_FAILURE: 'EXPORT_COUNTY_REPORTS_FAILURE',

    GET_USER_EFFICIENCY_REPORTS_REQUEST: 'GET_USER_EFFICIENCY_REPORTS_REQUEST',
    GET_USER_EFFICIENCY_REPORTS_SUCCESS: 'GET_USER_EFFICIENCY_REPORTS_SUCCESS',
    GET_USER_EFFICIENCY_REPORTS_FAILURE: 'GET_USER_EFFICIENCY_REPORTS_FAILURE',

    GET_DETAILED_USER_EFFICIENCY_REPORTS_REQUEST: 'GET_DETAILED_USER_EFFICIENCY_REPORTS_REQUEST',
    GET_DETAILED_USER_EFFICIENCY_REPORTS_SUCCESS: 'GET_DETAILED_USER_EFFICIENCY_REPORTS_SUCCESS',
    GET_DETAILED_USER_EFFICIENCY_REPORTS_FAILURE: 'GET_DETAILED_USER_EFFICIENCY_REPORTS_FAILURE',

    EXPORT_USER_EFFICIENCY_REPORTS_REQUEST: 'EXPORT_USER_EFFICIENCY_REPORTS_REQUEST',
    EXPORT_USER_EFFICIENCY_REPORTS_SUCCESS: 'EXPORT_USER_EFFICIENCY_REPORTS_SUCCESS',
    EXPORT_USER_EFFICIENCY_REPORTS_FAILURE: 'EXPORT_USER_EFFICIENCY_REPORTS_FAILURE',

    EXPORT_DETAILED_USER_EFFICIENCY_REPORTS_REQUEST: 'EXPORT_DETAILED_USER_EFFICIENCY_REPORTS_REQUEST',
    EXPORT_DETAILED_USER_EFFICIENCY_REPORTS_SUCCESS: 'EXPORT_DETAILED_USER_EFFICIENCY_REPORTS_SUCCESS',
    EXPORT_DETAILED_USER_EFFICIENCY_REPORTS_FAILURE: 'EXPORT_DETAILED_USER_EFFICIENCY_REPORTS_FAILURE',

    GET_AUTO_MANUAL_REPORTS_REQUEST: 'GET_AUTO_MANUAL_REPORTS_REQUEST',
    GET_AUTO_MANUAL_REPORTS_SUCCESS: 'GET_AUTO_MANUAL_REPORTS_SUCCESS',
    GET_AUTO_MANUAL_REPORTS_FAILURE: 'GET_AUTO_MANUAL_REPORTS_FAILURE',

    EXPORT_AUTO_MANUAL_REPORTS_REQUEST: 'EXPORT_AUTO_MANUAL_REPORTS_REQUEST',
    EXPORT_AUTO_MANUAL_REPORTS_SUCCESS: 'EXPORT_AUTO_MANUAL_REPORTS_SUCCESS',
    EXPORT_AUTO_MANUAL_REPORTS_FAILURE: 'EXPORT_AUTO_MANUAL_REPORTS_FAILURE',

    GET_ACCOUNTS_RECEIVABLE_REPORTS_REQUEST: 'GET_ACCOUNTS_RECEIVABLE_REPORTS_REQUEST',
    GET_ACCOUNTS_RECEIVABLE_REPORTS_SUCCESS: 'GET_ACCOUNTS_RECEIVABLE_REPORTS_SUCCESS',
    GET_ACCOUNTS_RECEIVABLE_REPORTS_FAILURE: 'GET_ACCOUNTS_RECEIVABLE_REPORTS_FAILURE',

    EXPORT_ACCOUNTS_RECEIVABLE_REPORTS_REQUEST: 'EXPORT_ACCOUNTS_RECEIVABLE_REPORTS_REQUEST',
    EXPORT_ACCOUNTS_RECEIVABLE_REPORTS_SUCCESS: 'EXPORT_ACCOUNTS_RECEIVABLE_REPORTS_SUCCESS',
    EXPORT_ACCOUNTS_RECEIVABLE_REPORTS_FAILURE: 'EXPORT_ACCOUNTS_RECEIVABLE_REPORTS_FAILURE',

    GET_FULFILLMENT_CANDIDATE_REPORTS_REQUEST: 'GET_FULFILLMENT_CANDIDATE_REPORTS_REQUEST',
    GET_FULFILLMENT_CANDIDATE_REPORTS_SUCCESS: 'GET_FULFILLMENT_CANDIDATE_REPORTS_SUCCESS',
    GET_FULFILLMENT_CANDIDATE_REPORTS_FAILURE: 'GET_FULFILLMENT_CANDIDATE_REPORTS_FAILURE',

    EXPORT_FULFILLMENT_CANDIDATE_REPORTS_REQUEST: 'EXPORT_FULFILLMENT_CANDIDATE_REPORTS_REQUEST',
    EXPORT_FULFILLMENT_CANDIDATE_REPORTS_SUCCESS: 'EXPORT_FULFILLMENT_CANDIDATE_REPORTS_SUCCESS',
    EXPORT_FULFILLMENT_CANDIDATE_REPORTS_FAILURE: 'EXPORT_FULFILLMENT_CANDIDATE_REPORTS_FAILURE',

    GET_FULFILLMENT_CUSTOMER_REPORTS_REQUEST: 'GET_FULFILLMENT_CUSTOMER_REPORTS_REQUEST',
    GET_FULFILLMENT_CUSTOMER_REPORTS_SUCCESS: 'GET_FULFILLMENT_CUSTOMER_REPORTS_SUCCESS',
    GET_FULFILLMENT_CUSTOMER_REPORTS_FAILURE: 'GET_FULFILLMENT_CUSTOMER_REPORTS_FAILURE',

    EXPORT_FULFILLMENT_CUSTOMER_REPORTS_REQUEST: 'EXPORT_FULFILLMENT_CUSTOMER_REPORTS_REQUEST',
    EXPORT_FULFILLMENT_CUSTOMER_REPORTS_SUCCESS: 'EXPORT_FULFILLMENT_CUSTOMER_REPORTS_SUCCESS',
    EXPORT_FULFILLMENT_CUSTOMER_REPORTS_FAILURE: 'EXPORT_FULFILLMENT_CUSTOMER_REPORTS_FAILURE',

    GET_PRODUCT_ASSIGNMENT_REPORTS_REQUEST: 'GET_PRODUCT_ASSIGNMENT_REPORTS_REQUEST',
    GET_PRODUCT_ASSIGNMENT_REPORTS_SUCCESS: 'GET_PRODUCT_ASSIGNMENT_REPORTS_SUCCESS',
    GET_PRODUCT_ASSIGNMENT_REPORTS_FAILURE: 'GET_PRODUCT_ASSIGNMENT_REPORTS_FAILURE',

    EXPORT_PRODUCT_ASSIGNMENT_REPORTS_REQUEST: 'EXPORT_PRODUCT_ASSIGNMENT_REPORTS_REQUEST',
    EXPORT_PRODUCT_ASSIGNMENT_REPORTS_SUCCESS: 'EXPORT_PRODUCT_ASSIGNMENT_REPORTS_SUCCESS',
    EXPORT_PRODUCT_ASSIGNMENT_REPORTS_FAILURE: 'EXPORT_PRODUCT_ASSIGNMENT_REPORTS_FAILURE',
}

export const reportService = {
    getAllReportService,
    exportReportData,
    getTurnAroundReportService,
    getRevenueReportService,
    exportTurnAroundReportData,
    exportRevenueReportService,
    exportDailyOrderDetailsService,
    getCountyReportService,
    exportCountyReportService,
    getUserEfficiencyReportService,
    exportUserEfficiencyReportService,
    getAutoVsManualReportService,
    exportAutoVsManualReportService,
    getDetailedUserEfficiencyReportService,
    exportDetailedUserEfficiencyReportService,
    getAccountsReceivableReportService,
    getFulfillmentCandidateReportService,
    exportFulfillmentCandidateReportService,
    getFulfillmentCustomerReportService,
    getProductAssignmentReportService,
    exportProductAssignmentReportService,
    exportFulfillmentCustomerReportService,
    exportAccountsReceivableService
}


function exportData(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportReportData(query)
            .then(
                reportData => dispatch(success(reportData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_REPORTS_DATA_REQUEST}
    }

    function success(reportData) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_REPORTS_DATA_SUCCESS, reportData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_REPORTS_DATA_FAILURE, error}
    }
}

function exportTurnAroundTimeDataAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportTurnAroundReportData(query)
            .then(
                reportData => dispatch(success(reportData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_TURN_AROUND_REPORTS_DATA_REQUEST}
    }

    function success(reportData) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_TURN_AROUND_REPORTS_DATA_SUCCESS, reportData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_TURN_AROUND_REPORTS_DATA_FAILURE, error}
    }
}


function exportRevenueReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportRevenueReportService(query)
            .then(
                reportData => dispatch(success(reportData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_REVENUE_REPORTS_DATA_REQUEST}
    }

    function success(reportData) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_REVENUE_REPORTS_DATA_SUCCESS, reportData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_REVENUE_REPORTS_DATA_FAILURE, error}
    }
}


function exportCountyReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportCountyReportService(query)
            .then(
                countyReport => dispatch(success(countyReport)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_COUNTY_REPORTS_REQUEST}
    }

    function success(countyReport) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_COUNTY_REPORTS_SUCCESS, countyReport}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_COUNTY_REPORTS_FAILURE, error}
    }
}

function exportAccountsReceivableAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportAccountsReceivableService(query)
            .then(
                report => dispatch(success(report)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_ACCOUNTS_RECEIVABLE_REPORTS_REQUEST}
    }

    function success(report) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_ACCOUNTS_RECEIVABLE_REPORTS_SUCCESS, report}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_ACCOUNTS_RECEIVABLE_REPORTS_FAILURE, error}
    }
}


function exportUserEfficiencyReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportUserEfficiencyReportService(query)
            .then(
                countyReport => dispatch(success(countyReport)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_USER_EFFICIENCY_REPORTS_REQUEST}
    }

    function success(countyReport) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_USER_EFFICIENCY_REPORTS_SUCCESS, countyReport}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_USER_EFFICIENCY_REPORTS_FAILURE, error}
    }

}

function exportDetailedUserEfficiencyReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportDetailedUserEfficiencyReportService(query)
            .then(
                userEfficiency => dispatch(success(userEfficiency)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_DETAILED_USER_EFFICIENCY_REPORTS_REQUEST}
    }

    function success(userEfficiency) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_DETAILED_USER_EFFICIENCY_REPORTS_SUCCESS, userEfficiency}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_DETAILED_USER_EFFICIENCY_REPORTS_FAILURE, error}
    }

}

function exportAutoVsManualReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportAutoVsManualReportService(query)
            .then(
                countyReport => dispatch(success(countyReport)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_AUTO_MANUAL_REPORTS_REQUEST}
    }

    function success(countyReport) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_AUTO_MANUAL_REPORTS_SUCCESS, countyReport}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_AUTO_MANUAL_REPORTS_FAILURE, error}
    }

}


// Export Daily Order Count Action
function exportDailyOrderCountAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportDailyOrderDetailsService(query)
            .then(
                reportData => dispatch(success(reportData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_DAILY_ORDER_COUNT_DETAILS_REQUEST}
    }

    function success(reportData) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_DAILY_ORDER_COUNT_DETAILS_SUCCESS, reportData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_DAILY_ORDER_COUNT_DETAILS_FAILURE, error}
    }
}


function getAllData(query) {
    return dispatch => {
        dispatch(request());

        reportService.getAllReportService(query)
            .then(
                reports => dispatch(success(reports)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.GET_ALL_REPORTS_REQUEST}
    }

    function success(reports) {
        return {type: reportConstants.GET_ALL_REPORTS_SUCCESS, reports}
    }

    function failure(error) {
        return {type: reportConstants.GET_ALL_REPORTS_FAILURE, error}
    }
}

function getTurnAroundReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getTurnAroundReportService(query)
            .then(
                reports => dispatch(success(reports)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.GET_TURN_AROUND_REPORTS_REQUEST}
    }

    function success(reports) {
        return {type: reportConstants.GET_TURN_AROUND_REPORTS_SUCCESS, reports}
    }

    function failure(error) {
        return {type: reportConstants.GET_TURN_AROUND_REPORTS_FAILURE, error}
    }
}

function getFulfillmentCandidateReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getFulfillmentCandidateReportService(query)
            .then(
                fulfillmentCandidateReport => dispatch(success(fulfillmentCandidateReport)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.GET_FULFILLMENT_CANDIDATE_REPORTS_REQUEST}
    }

    function success(fulfillmentCandidateReport) {
        return {type: reportConstants.GET_FULFILLMENT_CANDIDATE_REPORTS_SUCCESS, fulfillmentCandidateReport}
    }

    function failure(error) {
        return {type: reportConstants.GET_FULFILLMENT_CANDIDATE_REPORTS_FAILURE, error}
    }
}


function getFulfillmentCustomerReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getFulfillmentCustomerReportService(query)
            .then(
                fulfillmentCustomerReport => dispatch(success(fulfillmentCustomerReport)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.GET_FULFILLMENT_CUSTOMER_REPORTS_REQUEST}
    }

    function success(fulfillmentCustomerReport) {
        return {type: reportConstants.GET_FULFILLMENT_CUSTOMER_REPORTS_SUCCESS, fulfillmentCustomerReport}
    }

    function failure(error) {
        return {type: reportConstants.GET_FULFILLMENT_CUSTOMER_REPORTS_FAILURE, error}
    }
}

function exportFulfillmentCustomerReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportFulfillmentCustomerReportService(query)
            .then(
                fulfillmentCustomerReport => dispatch(success(fulfillmentCustomerReport)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_FULFILLMENT_CUSTOMER_REPORTS_REQUEST}
    }

    function success(fulfillmentCustomerReport) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_FULFILLMENT_CUSTOMER_REPORTS_SUCCESS, fulfillmentCustomerReport}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_FULFILLMENT_CUSTOMER_REPORTS_FAILURE, error}
    }

}

function getProductAssignmentReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getProductAssignmentReportService(query)
            .then(
                productAssignment => dispatch(success(productAssignment)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.GET_PRODUCT_ASSIGNMENT_REPORTS_REQUEST}
    }

    function success(productAssignmentReport) {
        return {type: reportConstants.GET_PRODUCT_ASSIGNMENT_REPORTS_SUCCESS, productAssignmentReport}
    }

    function failure(error) {
        return {type: reportConstants.GET_PRODUCT_ASSIGNMENT_REPORTS_FAILURE, error}
    }
}


function exportProductAssignmentReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportProductAssignmentReportService(query)
            .then(
                productAssignmentReport => dispatch(success(productAssignmentReport)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_PRODUCT_ASSIGNMENT_REPORTS_REQUEST}
    }

    function success(productAssignmentReport) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_PRODUCT_ASSIGNMENT_REPORTS_SUCCESS, productAssignmentReport}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_PRODUCT_ASSIGNMENT_REPORTS_FAILURE, error}
    }
}


    function exportFulfillmentCandidateReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.exportFulfillmentCandidateReportService(query)
            .then(
                fulfillmentCandidateReport => dispatch(success(fulfillmentCandidateReport)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.EXPORT_FULFILLMENT_CANDIDATE_REPORTS_REQUEST}
    }

    function success(fulfillmentCandidateReport) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_FULFILLMENT_CANDIDATE_REPORTS_SUCCESS, fulfillmentCandidateReport}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: reportConstants.EXPORT_FULFILLMENT_CANDIDATE_REPORTS_FAILURE, error}
    }

}

function getAccountsReceivableReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getAccountsReceivableReportService(query)
            .then(
                reports => dispatch(success(reports)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.GET_ACCOUNTS_RECEIVABLE_REPORTS_REQUEST}
    }

    function success(reports) {
        return {type: reportConstants.GET_ACCOUNTS_RECEIVABLE_REPORTS_SUCCESS, reports}
    }

    function failure(error) {
        return {type: reportConstants.GET_ACCOUNTS_RECEIVABLE_REPORTS_FAILURE, error}
    }
}


function getRevenueReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getRevenueReportService(query)
            .then(
                revenueReports => dispatch(success(revenueReports)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.GET_REVENUE_REPORTS_REQUEST}
    }

    function success(revenueReports) {
        return {type: reportConstants.GET_REVENUE_REPORTS_SUCCESS, revenueReports}
    }

    function failure(error) {
        return {type: reportConstants.GET_REVENUE_REPORTS_FAILURE, error}
    }
}


function getCountyReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getCountyReportService(query)
            .then(
                countyReports => dispatch(success(countyReports)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportConstants.GET_COUNTY_REPORTS_REQUEST}
    }

    function success(countyReports) {
        return {type: reportConstants.GET_COUNTY_REPORTS_SUCCESS, countyReports}
    }

    function failure(error) {
        return {type: reportConstants.GET_COUNTY_REPORTS_FAILURE, error}
    }
}


function getUserEfficiencyReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getUserEfficiencyReportService(query)
            .then(
                userEffReports => dispatch(success(userEffReports)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: reportConstants.GET_USER_EFFICIENCY_REPORTS_REQUEST}
    }

    function success(userEffReports) {
        return {type: reportConstants.GET_USER_EFFICIENCY_REPORTS_SUCCESS, userEffReports}
    }

    function failure(error) {
        return {type: reportConstants.GET_USER_EFFICIENCY_REPORTS_FAILURE, error}
    }
}


function getDetailedUserEfficiencyReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getDetailedUserEfficiencyReportService(query)
            .then(
                detailedUserEffReports => dispatch(success(detailedUserEffReports)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: reportConstants.GET_DETAILED_USER_EFFICIENCY_REPORTS_REQUEST}
    }

    function success(detailedUserEffReports) {
        return {type: reportConstants.GET_DETAILED_USER_EFFICIENCY_REPORTS_SUCCESS, detailedUserEffReports}
    }

    function failure(error) {
        return {type: reportConstants.GET_DETAILED_USER_EFFICIENCY_REPORTS_FAILURE, error}
    }
}


function getAutoVsManualReportAction(query) {
    return dispatch => {
        dispatch(request());
        reportService.getAutoVsManualReportService(query)
            .then(
                autoManualReports => dispatch(success(autoManualReports)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: reportConstants.GET_AUTO_MANUAL_REPORTS_REQUEST}
    }

    function success(autoManualReports) {
        return {type: reportConstants.GET_AUTO_MANUAL_REPORTS_SUCCESS, autoManualReports}
    }

    function failure(error) {
        return {type: reportConstants.GET_AUTO_MANUAL_REPORTS_FAILURE, error}
    }
}

/*********** Reducers started here. ******************/

//get all user reducer
export function getAllReportsReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants.GET_ALL_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants.GET_ALL_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.reports
            };
        case reportConstants.GET_ALL_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}

export function getTurnAroundReportsReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants.GET_TURN_AROUND_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants.GET_TURN_AROUND_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.reports
            };
        case reportConstants.GET_TURN_AROUND_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}

export function getRevenueReportReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants.GET_REVENUE_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants.GET_REVENUE_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.revenueReports
            };
        case reportConstants.GET_REVENUE_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}


export function getCountyReportReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants.GET_COUNTY_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants.GET_COUNTY_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.countyReports
            };
        case reportConstants.GET_COUNTY_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}


export function getAccountsReceivableReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants. GET_ACCOUNTS_RECEIVABLE_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants. GET_ACCOUNTS_RECEIVABLE_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.reports
            };
        case reportConstants. GET_ACCOUNTS_RECEIVABLE_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}


export function getUserEfficiencyReportReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants.GET_USER_EFFICIENCY_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants.GET_USER_EFFICIENCY_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.userEffReports
            };
        case reportConstants.GET_USER_EFFICIENCY_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}



export function getDetailedUserEfficiencyReportReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants.GET_DETAILED_USER_EFFICIENCY_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants.GET_DETAILED_USER_EFFICIENCY_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.detailedUserEffReports
            };
        case reportConstants.GET_DETAILED_USER_EFFICIENCY_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}


export function getAutoManualReportReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants.GET_AUTO_MANUAL_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants.GET_AUTO_MANUAL_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.autoManualReports
            };
        case reportConstants.GET_AUTO_MANUAL_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}
export function getFulfillmentCandidateReportReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants.GET_FULFILLMENT_CANDIDATE_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants.GET_FULFILLMENT_CANDIDATE_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.fulfillmentCandidateReport
            };
        case reportConstants.GET_FULFILLMENT_CANDIDATE_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}


export function getFulfillmentCustomerReportReducer(state = {}, action) {
    switch (action.type) {
        case reportConstants.GET_FULFILLMENT_CUSTOMER_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportConstants.GET_FULFILLMENT_CUSTOMER_REPORTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.fulfillmentCustomerReport
            };
        case reportConstants.GET_FULFILLMENT_CUSTOMER_REPORTS_FAILURE:
            return {};
        default:
            return state
    }
}

    export function getProductAssignmentReportReducer(state = {}, action) {
        switch (action.type) {
            case reportConstants.GET_PRODUCT_ASSIGNMENT_REPORTS_REQUEST:
                return {
                    ...state,
                    loading: true,
                    loggingIn: true,
                };
            case reportConstants.GET_PRODUCT_ASSIGNMENT_REPORTS_SUCCESS:
                return {
                    loggedIn: true,
                    loading: false,
                    items: action.productAssignmentReport
                };
            case reportConstants.GET_PRODUCT_ASSIGNMENT_REPORTS_FAILURE:
                return {};
            default:
                return state
        }
    }

//export customer Data
export async function exportReportData(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/report?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            resourceToFile(response.data, "turnTimeReport");
        })
        .catch((error) => console.log(error));
}

// Export Daily Order Count Service
export async function exportDailyOrderDetailsService(query) {

    let paramData = serialize(query)
    return axiosInstance.post(`/export/daily-order-count?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.xlsx",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            resourceToFile(response.data, "dailyOrderCountReport", ".xlsx");
        })
        .catch((error) => console.log(error));
}

//export customer Data
export async function exportTurnAroundReportData(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/turn-around-reports?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "TurnAroundReport");
            }
        })
        .catch(handleExportError);
}

//export Fulfillment Candidate Report
export async function exportFulfillmentCandidateReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/fulfillment-candidate-report?${paramData}`, null, {
            headers: {
                'Content-Disposition': "attachment; filename=template.csv",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }, responseType: 'arraybuffer',
        })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "FulfillmentCandidateReport");
            }
        })
        .catch(handleExportError);
}


//export Fulfillment Customer Report
export async function exportFulfillmentCustomerReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/fulfillment-customer-report?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "FulfillmentCustomerReport");
            }
        })
        .catch(handleExportError);
}

export async function exportProductAssignmentReportService(query) {
        let paramData = serialize(query)
        return axiosInstance.post(`/export/product-assignment-report?${paramData}`

            , null, {
                headers: {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }, responseType: 'arraybuffer',
            })
            .then((response) => {
                console.log(response.data.byteLength);
                if (response.data.byteLength === 0) {
                    showInfoToast("No data found to export!")
                } else {
                    resourceToFile(response.data, "ProductAssignmentReport");
                }
            })
            .catch(handleExportError);
}

export async function exportRevenueReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/revenue-reports?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "RevenueReport");
            }
        })
        .catch(handleExportError);
}

export async function getCountyReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/county-reports?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getUserEfficiencyReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/aggregate-user-efficiency-reports?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export async function getDetailedUserEfficiencyReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/detailed-user-efficiency-report?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getAutoVsManualReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/auto-manual-report?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export async function exportCountyReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/county-reports?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "CountyReport");
            }
        })
        .catch(handleExportError);
}

export async function exportAccountsReceivableService(query){
    let paramData = serialize(query)
    return axiosInstance.post(`/export/accounts-receivable-reports-csv?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "AccountsReceivableReport");
            }
        })
        .catch(handleExportError);
}


export async function exportUserEfficiencyReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/aggregate-user-efficiency-reports?${paramData}`
        , null, {
            headers: {
                'Content-Disposition': "attachment; filename=template.csv",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }, responseType: 'arraybuffer',
        })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "userEfficiencyReport");
            }
        })
        .catch(handleExportError);
}

export async function exportDetailedUserEfficiencyReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/detailed-user-efficiency-report?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "detailedUserEfficiencyReport");
            }
        })
        .catch(handleExportError);
}

export async function exportAutoVsManualReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/auto-manual-report?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "autoVsManualReport");
            }
        })
        .catch(handleExportError);
}

export async function getAllReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/turn-time-reports?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getProductAssignmentReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/product-assignment-report?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export async function getTurnAroundReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/turn-around-reports?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getAccountsReceivableReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/accounts-receivable-reports?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}
export async function getFulfillmentCandidateReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/fulfillment-candidate-report?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getFulfillmentCustomerReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/fulfillment-customer-report?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getRevenueReportService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/revenue-reports?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

