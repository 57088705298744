import Draggable from "react-draggable";
import {onDraggableStart} from "../../../../../common/helpers/utils";
import React, {useRef, useState} from "react";
import './Footer.css';

function AccessibilityModal({show, setShow}) {

    function dismissModal(e) {
        e.preventDefault();
        setShow(false);
    }

    const [bounds, setBounds] = useState({left: 0, right: 0});
    const draggleRef = useRef(null);

    return (
        <div className={show === false ? "modal fade" : "modal fade show customModal"} role="dialog"
             style={show === false ? {display: 'none'} : {display: 'block'}} id="confirmationModal"
             aria-labelledby="confirmationModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

            <Draggable handle=".handler"
                       bounds={bounds}
                       onStart={(event, uiData) => onDraggableStart(event, uiData, draggleRef, bounds, setBounds)}>

                <div className="modal-dialog modal-lg modal-dialog-centered" role="document" ref={draggleRef}>
                    <div className="modal-content group_main_form">
                        <div className={"modal-header handler"}>
                            <h4 className="modal-title" id="customerModalLabel">Accessibility</h4>
                            <i className="fa fa-arrows ml-3 mt-1  modal_drag_context_icon"/>
                            <button type="button" className="close" onClick={dismissModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mr-auto modal_body_height">
                            <div id="comp-llpfcvdc" className="KcpHeO tz5f0K comp-llpfcvdc wixui-rich-text"
                                 data-testid="richTextElement">
                                <p className="font_8 wixui-rich-text__text">
                                <span
                                    className="wixui-rich-text__text info_model_bold"><span
                                    className="wixui-rich-text__text info_model_span"><span
                                    className="wixui-rich-text__text font_8"><span
                                    className="wixui-rich-text__text info_model_bold">Web content accessibility compliance statement</span></span></span></span>
                                </p>


                                <p className="font_8 wixui-rich-text__text font_8">
                                    <span
                                        className="wixui-rich-text__text">TitleLogix Solutions, LLC is committed to facilitating the accessibility and usability of its website and TGX production platform for all our customers, including people with disabilities. &nbsp;We strive to meet the standards of the World Wide Web Consortium’s Web Content Accessibility Guidelines 2.0 Level AA (WCAG 2.0 AA). &nbsp;Our efforts are ongoing as technology advances.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text font_8"><span
                                    className="wixui-rich-text__text">If at any time you have specific questions or concerns about the accessibility of any particular web page, please contact us. Please be sure to specify the issue, stating if the issue is with the website, or the TGX platform, and state the page in your email, and we will make all reasonable efforts to make that page accessible for you.</span>
                                </p>

                                <p className="font_8 wixui-rich-text__text">


                                    <span className="wixui-rich-text__text">If you have question contact TItleLogix by phone at 713-453-8822 or by email at <span
                                        className="wixui-rich-text__text info_model_span_dc"><a
                                        href="mailto:inquire@titlelogixsolutions.com" target="_self"
                                        className="wixui-rich-text__text">inquire@titlelogixsolutions.com</a></span>, or by mail to:</span>


                                    <div className={"center_span mt-3"}>
                                        TitleLogix Solutions, LLC
                                        <br className="wixui-rich-text__text"/>
                                        13750 San Pedro Ave.
                                        <br className="wixui-rich-text__text"/>
                                        Suite 730
                                        <br className="wixui-rich-text__text"/>
                                        San Antonio, TX 78232
                                    </div>
                                </p>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className={"cursor btn cm_btn"}
                                    onClick={dismissModal}>Close
                            </button>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    )
}

export default AccessibilityModal;