import React, {useState} from "react";
import SystemPropertyField from "./SystemPropertyField";
import Breadcrumb from "../../../../common/components/Breadcrumb";

const AddSystemPropertyForm = () => {

    const [crumbs] = useState([
            {content: "Home", url: "/home"},
            {content: "System Properties", url: "/system-properties"},
            {content: "Add System Property", url: ""}
        ]
    )

    return (
        <>
            <div className="m-0 p-0 ">
                <div className="row">
                    <section id="content-wrapper">
                        <div className="col-lg-12 ml-auto">
                            <Breadcrumb crumbs={crumbs}/>
                        </div>
                        <div className="col-lg-8 m-auto">
                            <div className="form_design">
                                <SystemPropertyField formType={"Add"} systemPropertyData={null}/>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
export default AddSystemPropertyForm;